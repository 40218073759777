<template>
  <v-row>
    <v-col cols="12">
      <v-menu
        v-model="openMenu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template #activator="{ on, attrs }">
          <v-label>
            <span class="font-weight-bold form-label mb-4 primary--text">
              {{ $t(`dashboard.${label}`) }}</span
            >
          </v-label>
          <v-text-field
         
            @click:clear="handleRemoveFilter"
            hide-details
            readonly
            outlined
            v-model="form[filterName]"
            append-icon="mdi-calendar-month"
            v-bind="[attrs, $attrs]"
            v-on="on"
          ></v-text-field>
        </template>
        <!--for meladi date-->
        <v-date-picker
          v-if="show"
          v-model="form[filterName]"
          @change="handleFilter"
          @input="openMenu = false"
          :locale="$i18n.locale"
          min="1900-01-01"
          max="2100-01-01"
          show-adjacent-months
          :allowed-dates="allowedDates"
          :first-day-of-week="6"
        ></v-date-picker>
      </v-menu>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
import GenericComponent from '../GenericComponent'

export default {
  name: 'DatePickerFilter',
  inheritAttrs: false,
  extends: GenericComponent,
  data() {
    return {
      openMenu: false,
      show: true,
    }
  },
  computed: {
    ...mapGetters({ user: 'getUser' }),
  },
  methods: {
    formatDay(day) {
      return this.$moment(day).locale(this.$i18n.locale).format('D')
    },
    formatYear(year) {
      return this.$moment(year).locale(this.$i18n.locale).format('YYYY')
    },
    headerDateFormat(year) {
    
    },
  },
}
</script>
