import vue from "vue";
import { extend, ValidationObserver, ValidationProvider } from "vee-validate";
import * as rules from "vee-validate/dist/rules";

import i18n from "@/plugins/i18n";

//messages for all rules
const customMessages = {
  getMessage(_, values) {
    let mimesRule;
    if (typeof values == "object") {
      const spreadData = (({ _field_, _value_, _rule_, ...values }) => values)(
        values
      );
      mimesRule = Object.values(spreadData).join(",");
    }

    return i18n.t(`validation.${values._rule_}`, {
      field: i18n.t(`${values._field_}`),
      max: values.length,
      min: values.length,
      max_value: values.max,
      min_value: values.min,
      mimes: mimesRule,
      size: values.size
    });
  }
};

extend("strong_password", {
  validate: (value) => {
    let pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
    return pattern.test(value);
  },
  message: (_, values) => {
    return i18n.t(`validation.${values._rule_}`)
  },
});

extend('url', {
  validate: (value) => {
    const pattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
    return pattern.test(value);
  },
  message: (_, values) => {
    return i18n.t(`validation.${values._rule_}`)
  },
});

extend('not_greater_than', {
  params: ['target'],
  validate(value, { target }) {
    let val = document.getElementById(target).value;
    return parseFloat(value) <= parseFloat(val);
  },
  message: (_, values) => {
    return i18n.t(`validation.${values._rule_}`, { target: i18n.t(values.target) })
  },
});


extend('not_less_than', {
  params: ['target'],
  validate(value, { target }) {
    let val = document.getElementById(target).value;
    return parseFloat(value) >= parseFloat(val);
  },
  message: (_, values, target) => {
    console.log(values)
    return i18n.t(`validation.${values._rule_}`, { target: i18n.t(values.target) })
  },
});

extend('required', rules.required);
extend('numeric', rules.numeric);
//All rules
Object.keys(rules).forEach((rule) => {
  extend(rule, {
    ...rules[rule],
    message: customMessages.getMessage
  });
});

vue.component("ValidationProvider", ValidationProvider);
vue.component("ValidationObserver", ValidationObserver);