<template>
  <Button depressed large rounded outlined v-bind="$attrs" v-on="$listeners" class="pa-5 pe-0 activate-btn" :class="backgroundColor"
    :color="color" min-width="fit-content" style="transition: all 0.1s ease"
    :style="{ border: value ? '' : '1px solid #333 !important' }">
    {{ $t(text) }}
    <span v-html="icon" class="mx-3"></span>
  </Button>
</template>


<script>
export default {
  name: "ActiveBtn",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: "",
    },
  },
  computed: {
    icon() {
      return this.value
        ? '<i class="fa-regular fa-square-check fa-lg"></i>'
        : '<i class="far fa-square fa-lg"></i>';
    },
    backgroundColor() {
      return this.value ? "primary" : "white";
    },
    color() {
      return this.value ? "white" : "black";
    },
  },
};
</script>