export const settingServiceRoutes = {
  path: "/services/:slug/setting-service",
  to: "/services/:slug/setting-service",
  component: () => import("@/views/system/services/settingService/record"),
  name: "setting-service",
  icon: "setting-service.svg",
  allowed: true,
  order: 3,
  activeRoutes: ["setting_records"],
  meta: {
    permissions: "services_edit",
    title: "setting-service",
  },
};
