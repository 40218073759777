import axios from "@/plugins/axios";
import store from "@/store";
export default {
  state: () => ({
    services: {},
  })
  ,
  mutations: {
    SET_SERVICES(state, payload) {
      state.services = payload
    }
  },
  actions: {
    async setServices({ commit }, permissions) {
      const res = await axios.get(`services`);
      const services = res.data.data;
      let children = []
      commit('SET_SERVICES', services)
      services.forEach((service) => {
        if (service.type != 'form') {

          const allowedChildren = service.type && permissions.includes(`services-${service.type}-models_view`)

          children.push(
            {
              path: "",
              component: () => import("@/views/dynamic-service"),
              name: "record_dynamic-services",
              icon: "arrow.svg", // < arrow icon 
              to: "/dynamic-services",
              hidden: true,
              activeRoutes: ["record_dynamic-services", "add_serve"],
              allowed: true,
              allow_children: allowedChildren,
              meta: {
                runtime: true,
                title: service.name,
                permissions: "services",
              },
              children: [
                {
                  path: `:slug/:${service.type}`,
                  component: () => import(`@/views/dynamic-service/${service.type}`),
                  name: `record_dynamic-services_${service.type}`,
                  icon: "arrow.svg", // < arrow icon 
                  to: `/dynamic-services/${service.slug}/${service.type}`,
                  hidden: false,
                  activeRoutes: [`record_dynamic-services_${service.type}`, "add_dynamic-services_category"],
                  allowed: true,
                  allow_children: true,
                  meta: {
                    title: "record_dynamic-services",
                    permissions: `dynamic-services_${service.type}`,
                  },
                  children: [
                    {
                      path: "",
                      component: () => import(`@/views/dynamic-service/${service.type}/record`),
                      icon: "arrow.svg", // < arrow icon
                      to: `/dynamic-services/${service.type}`,
                      allowed: true,
                      meta: {
                        title: "record_cats",
                        permissions: "dynamic-services_cats_view",
                      },
                    },
                    {
                      path: "add",
                      component: () => import(`@/views/dynamic-service/${service.type}/actions`),
                      name: `record_dynamic-services_${service.type}`,
                      icon: "arrow.svg",
                      to: `/dynamic-services/${service.type}/add`,
                      allowed: false,
                      meta: {
                        title: "add_category",
                        permissions: `dynamic-services_${service.type}_create`,
                      },
                    },
                    {
                      path: ":id/edit",
                      component: () => import(`@/views/dynamic-service/${service.type}/actions`),
                      name: `edit_dynamic-services_${service.type}`,
                      icon: "mdi-square-show-outline",
                      hidden: true,
                      meta: {
                        title: `edit_dynamic-services_${service.type}`,
                        permissions: `edit_dynamic-services_${service.type}`,
                      },
                    },
                  ]
                },
                {
                  path: ":slug/categories",
                  component: () => import("@/views/dynamic-service/categories"),
                  icon: "arrow.svg", // < arrow icon 
                  to: `/dynamic-services/${service.slug}/categories`,
                  hidden: service.type == 'rental' || !permissions.includes('services-categories_view'),
                  activeRoutes: ["record_dynamic-services_categories", "add_dynamic-services_category"],
                  allowed: true,
                  allow_children: true,
                  meta: {
                    title: "categories",
                    permissions: "dynamic-services_categories",
                  },
                  children: [
                    {
                      path: "",
                      component: () => import("@/views/dynamic-service/categories/record"),
                      icon: "arrow.svg", // < arrow icon
                      to: `/dynamic-services/${service.slug}/categories`,
                      allowed: true,
                      meta: {
                        title: "record_cats",
                        permissions: "dynamic-services_cats_view",
                      },
                    },
                    {
                      path: "add",
                      component: () => import("@/views/dynamic-service/categories/actions"),

                      icon: "arrow.svg",
                      to: "/dynamic-services/categories/add",
                      allowed: false,
                      meta: {
                        title: "add_category",
                        permissions: "dynamic-services_categories_create",
                      },
                    },
                    {
                      path: ":id/edit",
                      component: () => import("@/views/dynamic-service/categories/actions"),
                      name: "edit_dynamic-services_categories",
                      icon: "mdi-square-show-outline",
                      hidden: true,
                      meta: {
                        title: "edit_dynamic-services_categories",
                        permissions: "edit_dynamic-services_categories",
                      },
                    },
                  ]
                },

              ]
            },

          )
        }
      }
      )
      return children;
    }
  },
  getters: {
    getServices(state) {
      return state.services
    },
  }
}

