export const excludeRoutes = {
  path: "excludes",
  to: "/dynamic-services/:slug/booking/:id/excludes",
  component: () => import("@/views/dynamic-service/booking/exclude/record"),
  icon: "exclude.svg",
  allowed: true,
  order :6,
  activeRoutes: ["exclude_records"],
  meta: {
      permissions : ["services-booking-models-excludes_view","services-booking-models-excludes-features_view"],
      title : "excludes_models",
  }

};