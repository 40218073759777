const asyncRoutes = [];
const context = require.context('./modules', false, /\.js$/);
context.keys().forEach((key) => {
  let fileName = key.replace(/(\.\/|\.js)/g, "");
  asyncRoutes.push(context(key)[fileName + "Routes"]);
});

asyncRoutes.sort((routeA, routeB) => {
  const orderA = routeA.order || 0;
  const orderB = routeB.order || 0;
  return orderA - orderB;
});

export { asyncRoutes };