<template>
    <v-row no-gutters class="white position-relative px-5 pb-16 pt-7">
        <v-col cols="12">
            <div class="upload-file">
                <div class="upload-file__custom">
                    <div class="upload-file__custom__bg">
                        <svg width="54" height="54" viewBox="0 0 54 54" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M20.249 12.375A3.37 3.37 0 0 1 23.624 9a3.37 3.37 0 0 1 3.375 3.375 3.37 3.37 0 0 1-3.375 3.375 3.37 3.37 0 0 1-3.375-3.375zM54 11.25v13.5C54 30.96 48.96 36 42.75 36H22.499c-6.21 0-11.25-5.04-11.25-11.25v-13.5c0-6.21 5.04-11.25 11.25-11.25h20.25C48.96 0 54 5.04 54 11.25zm-38.252 13.5c0 1.733.653 3.308 1.733 4.523l11.79-11.79c2.206-2.206 6.053-2.206 8.258 0l2.34 2.34a1.372 1.372 0 0 0 1.913 0l7.718-7.718v-.855c0-3.713-3.038-6.75-6.75-6.75H22.499c-3.713 0-6.75 3.037-6.75 6.75v13.5zm33.752 0v-6.277l-4.545 4.544c-2.205 2.206-6.053 2.206-8.258 0l-2.34-2.34c-.518-.517-1.373-.517-1.913 0L21.666 31.456c.27.045.54.045.833.045h20.25c3.713 0 6.75-3.038 6.75-6.75zm-8.798 15.84c-1.193-.338-2.43.382-2.768 1.575l-.652 2.385a6.629 6.629 0 0 1-3.173 4.073 6.713 6.713 0 0 1-5.13.652L9.448 43.92a6.751 6.751 0 0 1-4.725-8.303l2.16-8.01a2.275 2.275 0 0 0-1.575-2.767 2.275 2.275 0 0 0-2.768 1.575L.403 34.403c-1.643 5.985 1.89 12.194 7.875 13.837l19.53 5.355c.99.27 2.003.405 2.993.405 1.936 0 3.826-.495 5.558-1.485 2.61-1.485 4.478-3.892 5.288-6.795l.652-2.385c.338-1.193-.382-2.43-1.575-2.767l-.022.022z"
                                fill="#0F6FFD" fill-rule="nonzero" />
                        </svg>
                        <h4 class="my-3">
                            {{ $t(uploaded) }}
                        </h4>
                        <p class="size-12">
                            {{ $t(types) }}
                        </p>
                        <div v-if="progressShow" class="mt-4 px-5">
                            <v-progress-linear class="upload-file__progress" v-model="progress" color="primary">
                                <span>{{ progress }}%</span>
                            </v-progress-linear>
                        </div>
                        <v-file-input v-if="render" @change="upload" :multiple="multiple" v-model="files"
                            color="deep-purple accent-4" outlined>
                        </v-file-input>
                    </div>
                </div>
            </div>
        </v-col>
        <slot />
    </v-row>
</template>

<script>

export default {
    name: "File",
    data: () => ({
        files: [],
        render: true,
        src: null,
        progressShow: false,
        progress: 0,
        id: null
    }),
    methods: {
        progressBar() {
            this.progressShow = true
            this.progress = 0
            var x = setInterval(() => {
                this.progress += 3;
                if (this.progress >= 98) {
                    clearInterval(x);
                    this.progress = 99;
                }
            }, 100);
        },
        upload() {
            if (this.multiple) {
                this.multi()
            } else {
                this.single()
            }
            this.$emit('uploaded', this.files)
            this.reset()
        },
        reset() {
            this.render = false
            setTimeout(() => {
                this.render = true
            }, 1000)
        },
        single() {
            if (this.files)
                this.src = URL.createObjectURL(this.files);
        },
        multi() {
            if (this.files)
                for (let i = 0; i <= this.files.length; i++) {
                    this.files[i].path = URL.createObjectURL(this.files[i]);
                }
        }
    },
    props: {
        multiple: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: ""
        },
        hint: {
            type: String,
            default: ""
        },
        uploaded: {
            type: String,
            default: ""
        },
        types: {
            type: String,
            default: ""
        },
    }
}
</script>