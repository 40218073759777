<template>
  <div class="intro-info">
    <div
      class="secondary intro-info__border"
      :style="`min-height:${height}px`"
    ></div>
    <h4 class="mb-1" v-html="$t(heading)"></h4>
    <div class="intro-info__content" v-html="$t(content)"></div>
  </div>
</template>

<script>
export default {
  name: "IntroInfo",
  props: {
    heading: {
      type: String,
      default: "",
    },
    content: {
      type: String,
      default: "",
    },
    height: {
      type: String,
      default: "100",
    },
  },
};
</script>