import Vue from "vue";
import VueI18n from "vue-i18n";
import arLocale from "./ar.json";
import enLocale from "./en.json";
import Cookies from "js-cookie";
import store from "@/store";
Vue.use(VueI18n);
export const messages = {
  en: {
    ...enLocale
  },
  ar: {
    ...arLocale
  }
};
const i18n = new VueI18n({
  locale: store.getters["getLocale"] || "en",
  lazy: true,
  silentTranslationWarn: true,
  fallbackLocale: "en",
  messages
});

if (!Cookies.get("language")) {
  Cookies.set("language", "en")
}

document.documentElement.lang = Cookies.get("language") || 'en'
document.documentElement.dir = Cookies.get("language") == 'ar' ? 'rtl' : 'ltr'

export default i18n;
