export const mainInfoRoutes = {
  path: "/offers/:id/main_information",
  to: "/offers/:id/main_information",
  component: () => import("@/views/main-content/offers/main-info"),
  icon: "offer.svg",
  allowed: true,
  order : 1,
  activeRoutes: ["mainInfo_records"],
  meta: {
      permissions : "offers_view",
      title : "main_information_offer",
  }
};