<template>
  <v-card class="pa-2" min-height="600">
    <div class="d-flex">
      <div class="mx-2">
        <img alt="icon" width="30" height="30" :src="require(`@/assets/imgs/icons/${image}`)" />
      </div>
      <div class="mx-1">
        <h3 class="font-weight-bold">{{ $t(heading) }}</h3>
        <p class="mt-1" style="max-width: 500px">{{ $t(description) }}</p>
      </div>
    </div>
    <div class="mx-sm-12 mt-6">
      <ActiveBtn :value="value" @click="handleClick" :text="btnText" />
    </div>
    <v-row class="mt-8 pt-4">
      <v-col cols="6" offset="3">
        <div class="country-card">
          <img :src="require(`@/assets/imgs/${imgUrl}`)" height="100%" width="100%" alt="image" />
        </div>
        <p class="text-center mt-3 size-12 font-weight-medium">
          {{ $t(hint) }}
        </p>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
export default {
  name: "CountryCard",
  props: {
    image: {
      type: String,
      default: "",
    },
    heading: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    btnText: {
      type: String,
      default: "",
    },
    imgUrl: {
      type: String,
      default: "",
    },
    hint: {
      type: String,
      default: "",
    },
    value: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    handleClick() {
      this.$emit("input", !this.value);
    },
  },
};
</script>