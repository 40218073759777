<template>
  <div>
    <v-label v-if="showLabel" :for="name">
      <strong class="font-weight-dark"> {{ title ? $t(title) : $t(name) }}</strong>
    </v-label>
    <v-text-field   :placeholder="$t(placeholder)" :label="$t(label)"  class="mt-1 fill-field" rounded outlined flat v-bind="$attrs"
      v-on="$listeners" @input="clearServerErrors" :hint="showHint ? $t(`hint_${hint ? hint : name}`) : ''"
      persistent-hint>
    </v-text-field>
  </div>
</template>

<script>
export default {
  props: {
    focus: {
      type: Boolean,
      default: () => false,
    },
    name: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    hint: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    showHint: {
      type: Boolean,
      default: true,
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    clearServerErrors() {
      if (this.serverErrors) {
        this.$store.dispatch("ClearServerErrors");
      }
    },
  },
};
</script>
