import axios from "axios";
import store from "@/store";
import { baseURL } from "@/plugins/axios/baseUrl";
import { getToken, removeToken } from "@/utils/auth";
import router from "@/router";
const api = axios.create({
  timeout: 60000, 
  baseURL: baseURL,
});
api.interceptors.request.use(
  async (config) => {
    if (store.getters.token) {
      config.headers["Authorization"] = `${"Bearer " + getToken()}`;
    }
    config.headers["X-locale"] = store.getters["getLocale"];
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
api.interceptors.response.use(
  (response) => {
    store.dispatch("ClearServerErrors");
    let { method } = response.config;
    if (method === "post" || method === "put" || method === "delete") {
      if (response.data.message) {
        store.dispatch("ShowNotification", {
          text: `${response.data.message}   `,
          color: "success",
        });
      }
    }
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      store.dispatch("ShowNotification", {
        text: error.response.data.message,
        color: "red",
      });
      removeToken();
      return router.push("/login");
    }
    if ([422, 409].includes(error.response.status)) {
      store.dispatch("ShowNotification", {
        text: error.response.data.message,
        color: "red",
      });
    }
    if (!getToken()) {
      store.dispatch("ShowNotification", {
        text: error.response.data.message,
        color: "red",
      });
    }
    const { errors } = error.response.data;
    store.dispatch("SetServerErrors", errors);
    return Promise.reject(error);
  }
);

export default api;
