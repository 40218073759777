export const specificationRoutes = {
  path: "specification",
  to: "/dynamic-services/:slug/booking/:id/specification",
  component: () => import("@/views/dynamic-service/booking/specifications/record"),
  
  icon: "specs.svg",
  allowed: true,
  order : 12,
  activeRoutes: ["specification_records"],
  meta: {
      permissions : "services-booking-models-specifications_view",
      title : "specification",
  }

};