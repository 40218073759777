<template>
  <div class="counter-input">
    <v-row>
      <v-col cols="12" md="6" class="align-self-center">
        <v-label v-if="showLabel" :for="label">
          <strong class="font-weight-dark px-2"> {{ $t(label) }}</strong>
        </v-label>
      </v-col>
      <v-col cols="12" md="6" class="algin-self-center">
        <v-text-field :value="value" readonly v-bind="$attrs" type="number" rounded v-on="$listeners" flat>
          <template #prepend-inner>
            <Button small  class="plus" @click.stop="onIncrement"
              :loading="$attrs.loadingIncrement" :disabled="value >= max || !isValid" style="min-width: 40px !important; max-width: 40px !important;">
              <v-icon>mdi-plus</v-icon>
            </Button>
          </template>
          <template #append>
            <Button :disabled="value <= min || !isValid" small  class="minus"
              @click.stop="onDecrement" :loading="$attrs.loadingDecrement" style="min-width: 40px !important; max-width: 40px !important;">
              <v-icon>mdi-minus</v-icon>
            </Button >
          </template>
        </v-text-field>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "CounterInput",
  inheritAttrs: false,
  props: {
    form: {
      type: Object,
      default: () => { },
    },
    name: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 100000,
    },
    isValid: {
      type: Boolean,
      default: true,
    },
    value: {
      type: String,
      default: "",
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    onDecrement() {
      if (this.value > this.min) {
        this.value--;
      }
      this.$emit("on-decrement");
    },
    onIncrement() {
      if (this.value < this.max) {
        this.value++;
        this.$emit("on-increment");
      }
    },
  },
};
</script>

<style lang="scss">
.counter-input {
  input {
    text-align: center !important;
  }

  .v-input__slot {
    padding: 0 10px !important;
    background-color: transparent !important;
  }
}
</style>