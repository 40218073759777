export const informationsRoutes = {
  path: "/countries/:id/informations",
  to: "/countries/:id/informations",
  icon: "mdi-account-multiple-outline",
  activeRoutes: ["record_informations"] ,
  allow_children: false,
//   order : 16,
  showInMenu: true,
  
  meta: {
      title: "view-informations",
      permissions: "countries-informations",
      
  },
  component: () => import("@/views/countries/informations"),
  children: [
      {
          path: "/",
          component: () => import("@/views/countries/informations/record"),
          
          icon: "mdi-file-document-multiple-outline",
          allowed: true,
          meta: {
              title: "record_informations",
              permissions: "countries-informations_view"
          }
      },
  ],
};
