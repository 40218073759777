<template>
  <section class="heading">
    <div class="d-flex align-center">
      <div class="mx-2" v-if="showFirst">
        <img v-if="imageName" class="mt-3" :width="width" :height="height"
          :src="require(`@/assets/imgs/icons/${imageName}`)" />
        <v-icon v-else color="black" large>
          {{ icon }}
        </v-icon>
      </div>
      <div>
        <div class="d-flex align-center">
          <h3 class="font-weight-bold">
            <span>
              {{ $t(title) }}
            </span>
            <span v-if="countryName" class="size-12 font-weight-medium">
              &gt; {{ countryName }}
            </span>
            <span v-if="nested" class="size-12 font-weight-medium">
              {{ $t(nested) }}
            </span>
          </h3>
          <img v-if="!showFirst" class="mx-1" width="35" height="35"
            :src="require(`@/assets/imgs/icons/${imageName}`)" />
        </div>
      
        <p v-if="dynamicDescription" class="mt-1" v-html="$t(dynamicDescription)"></p>
        <p v-else class="mt-1" v-html="$t(description)"></p>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "Heading",
  props: {
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "",
    },
    imageName: {
      type: String,
      default: "",
    },
    showFirst: {
      type: Boolean,
      default: true,
    },
    countryName: {
      type: String,
      default: "",
    },
    dynamicDescription: {
      type: String,
      default: "",
    },
    nested: {
      type: String,
      default: "",
    },
    width: {
      type: String,
      default: "40",
    },
    height: {
      type: String,
      default: "40",
    },
  },
};
</script>
