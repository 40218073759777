export const destinationRoutes = {
  path: "destinations",
  to: "/dynamic-services/:slug/booking/:id/destinations",
  component: () => import("@/views/dynamic-service/booking/destinations/actions"),
  icon: "destinations.svg",
  allowed: true,
  order : 8,
  activeRoutes: ["term_records"],
  meta: {
      permissions : "services-booking-models_view",
      title : "destinations",
  }

};