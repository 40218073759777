export const includeRoutes = {
  path: "/services/:slug/includes",
  to: "/services/:slug/includes",
  component: () => import("@/views/system/services/include"),

  icon: "include.svg",
  allowed: true,
  order: 11,
  activeRoutes: ["include_records"],
  meta: {
    permissions: "services-includes_view",
    title: "include_records",
  },
  children: [
    {
      path: "",
      component: () => import("@/views/system/services/include/record/index"),

      icon: "arrow.svg", // < arrow icon
      to: "/services/:slug/include",
      allowed: true,
      meta: {
        title: "record_include",
        permissions: "services-includes_view",
      },
    },
  ],
};
