import {asyncOfferRoutes} from "@/router/offersRoutes"
export const contentRoutes = {
  path: "/content",
  to: "/content",
  icon: "tag-white.svg",
  activeRoutes: ["record_content"],
  meta: {
    title: "main_content",

    permissions: ["content_view","features_view","tags_view","hotels_view","banners_view", "blogs_view", "blog-categories_view","offers_view"]
 
  },
  
  allow_children: true,
  showInMenu: true,
  order: 4,
  component: () => import("@/views/main-content"),
  children: [
    {
      path: "/blogs",
      component: () => import("@/views/main-content/blogs"),
      icon: "arrow.svg", // < arrow icon 
      to: "/blogs",
      hidden: true,
      activeRoutes: [],
      allowed: true,
      allow_children: true,
      meta: {
        title: "blogs",
        permissions: ["blogs_view", "blog-categories_view"],
      },
      children: [
        {
          path: "",
          component: () => import("@/views/main-content/blogs/record"),
  
          icon: "arrow.svg", // < arrow icon 
          to: "/blogs",
          allow_children: true,
          activeRoutes: ["record_blogs"],
          allowed: true,
          meta: {
            title: "record_blogs",
            permissions: "blogs_view",
          },
        },


        {
          path: "add",
          component: () => import("@/views/main-content/blogs/actions"),
  
          icon: "arrow.svg",
          to: "/blogs/add",
          allowed: false,
          meta: {
            title: "add_user",
            permissions: "blogs_create",
          },
        },
        {
          path: ":id/edit",
          component: () => import("@/views/main-content/blogs/actions"),
          
          icon: "mdi-square-show-outline",
          hidden: true,
          meta: {
            title: "edit_blogs",
            permissions: "blogs_edit",
          },
        },
        {
          path: ":id/gallery",
          component: () => import("@/views/main-content/blogs/gallery"),
          
          icon: "mdi-square-show-outline",
          hidden: true,
          meta: {
            title: "gallery_blogs",
            permissions: "blogs_view",
          },
        },
        {
          path: "/blogs/categories",
          component: () => import("@/views/main-content/blogs/category"),
          
          icon: "arrow.svg", // < arrow icon 
          to: "/blogs/categories",
          hidden: false,
          activeRoutes: ["record_categories", "add_category"],
          allowed: true,
          allow_children: true,
          meta: {
            title: "categories",
            permissions: "blog-categories_view",
          },
          children: [
            {
              path: "",
              component: () => import("@/views/main-content/blogs/category/record"),
              
              icon: "arrow.svg", // < arrow icon
              to: "/cats",
              allowed: true,
              meta: {
                title: "record_cats",
                permissions: "cats_view",
              },
            },


            {
              path: "add",
              component: () => import("@/views/main-content/blogs/category/actions"),
              
              icon: "arrow.svg",
              to: "/categories/add",
              allowed: false,
              meta: {
                title: "add_category",
                permissions: "blog-categories_create",
              },
            },
            {
              path: ":id/edit",
              component: () => import("@/views/main-content/blogs/category/actions"),
              
              icon: "mdi-square-show-outline",
              hidden: true,
              meta: {
                title: "edit_categories",
                permissions: "blog-categories_edit",
              },
            },


          ]
        },

      ]
    },
    {
      path: "/banners",
      to: "/banners",
      icon: "arrow.svg",
      activeRoutes: ["record_banners", "add_banners", "banners"],
      allowed: true,
      hideInMenu: false,
      allow_children: false,
      
      meta: {
        title: "banners",
        permissions: "banners_view",
      },
      component: () => import("@/views/main-content/banners"),
      children: [
        {
          path: "",
          component: () => import("@/views/main-content/banners/record/index"),
          
          icon: "arrow.svg", // < arrow icon
          to: "/banners",
          allowed: true,
          meta: {
            title: "record_banners",
            permissions: "banners_view",
          },
        },
        {
          path: "add",
          component: () => import("@/views/main-content/banners/actions"),
          
          icon: "arrow.svg",
          to: "/banners/add",
          allowed: true,
          meta: {
            title: "add_user",
            permissions: "banners_create",
          },
        },
        {
          path: ":id/edit",
          component: () => import("@/views/main-content/banners/actions"),
          
          icon: "mdi-square-show-outline",
          hidden: true,
          meta: {
            title: "banners_show",
            permissions: "banners_edit",
          },
        },
      ],
    },
    {
      path: "/tags",
      to: "/tags",
      icon: "arrow.svg",
      activeRoutes: ["record_tags", "add_tags", "tags"],
      allowed: true,
      hideInMenu: false,
      allow_children: false,
      
      meta: {
        title: "tags",
        permissions: "tags_view",
      },
      component: () => import("@/views/main-content/tags"),
      children: [
        {
          path: "",
          component: () => import("@/views/main-content/tags/record/index"),
          
          icon: "arrow.svg", // < arrow icon
          to: "/tags",
          allowed: true,
          meta: {
            title: "record_tags",
            permissions: "tags_view",
          },
        },
        {
          path: "add",
          component: () => import("@/views/main-content/tags/actions"),
          
          icon: "arrow.svg",
          to: "/tags/add",
          allowed: true,
          meta: {
            title: "add_user",
            permissions: "tags_create",
          },
        },
        {
          path: ":id/edit",
          component: () => import("@/views/main-content/tags/actions"),
          
          icon: "mdi-square-show-outline",
          hidden: true,
          meta: {
            title: "tags_show",
            permissions: "tags_view",
          },
        },
      ],
    },
    {
      path: "/features",
      to: "/features",
      icon: "arrow.svg",
      activeRoutes: ["record_features", "add_features", "features"],
      allowed: true,
      hideInMenu: false,
      allow_children: false,
      
      meta: {
        title: "features",
        permissions: "features_view",
      },
      component: () => import("@/views/main-content/features"),
      children: [
        {
          path: "",
          component: () => import("@/views/main-content/features/record/index"),
          
          icon: "arrow.svg", // < arrow icon
          to: "/features",
          allowed: true,
          meta: {
            title: "record_features",
            permissions: "features_view",
          },
        },
        {
          path: "add",
          component: () => import("@/views/main-content/features/actions"),
          
          icon: "arrow.svg",
          to: "/features/add",
          allowed: true,
          meta: {
            title: "add_user",
            permissions: "features_create",
          },
        },
        {
          path: ":id/edit",
          component: () => import("@/views/main-content/features/actions"),
          
          icon: "mdi-square-show-outline",
          hidden: true,
          meta: {
            title: "features_show",
            permissions: "features_view",
          },
        },
      ],
    },
    {
      path: "/hotels",
      to: "/hotels",
      icon: "arrow.svg",
      activeRoutes: ["record_hotels", "add_hotels", "hotels"],
      allowed: true,
      hideInMenu: false,
      allow_children: false,
      
      meta: {
        title: "hotels",
        permissions: "hotels_view",
      },
      component: () => import("@/views/main-content/hotels"),
      children: [
        {
          path: "",
          component: () => import("@/views/main-content/hotels/record/index"),
          
          icon: "arrow.svg", // < arrow icon
          to: "/hotels",
          allowed: true,
          meta: {
            title: "record_hotels",
            permissions: "hotels_view",
          },
        },
        {
          path: "add",
          component: () => import("@/views/main-content/hotels/actions"),
          
          icon: "arrow.svg",
          to: "/hotels/add",
          allowed: true,
          meta: {
            title: "add_hotel",
            permissions: "hotels_create",
          },
        },
        {
          path: ":id/edit",
          component: () => import("@/views/main-content/hotels/actions"),
          
          icon: "mdi-square-show-outline",
          hidden: true,
          meta: {
            title: "hotels_show",
            permissions: "hotels_view",
          },
        },
        {
          path: ":id/gallery",
          component: () => import("@/views/main-content/hotels/gallery"),
          
          icon: "mdi-square-show-outline",
          hidden: true,
          meta: {
            title: "hotels_gallery",
            permissions: "hotels_gallery",
          },
        },
      ],
    },
    {
      path: "/offers",
      component: () => import("@/views/main-content/offers"),
      
      icon: "arrow.svg", // < arrow icon 
      to: "/offers",
      hidden:true,
      activeRoutes: ["record_offers" , "add_offer"],
      allowed: true,
      allow_children: true,
      meta: {
        title: "offers",
        permissions: "offers_view",
      },
      children:[
        {
          path: "",
          component: () => import("@/views/main-content/offers/record"),
          
          icon: "arrow.svg", // < arrow icon 
          to: "/offers",
          allow_children:true,
          activeRoutes: ["record_offers"],
          allowed: true,
          meta: {
            title: "record_offers",
            permissions: "offers_view",
          },
        },
        {
          path: "add",
          component: () => import("@/views/main-content/offers/main-info"),
          
          icon: "arrow.svg", // < arrow icon 
          to: "/offers/add",
          allow_children:true,
          activeRoutes: ["add_offer"],
          allowed: true,
 
          meta: {
            title: "add_offer",
            permissions: "offers_create",
          },
        },

        {
          path: ":slug",
          component: () => import("@/layout/offerLayout") ,
          
          icon: "mdi-file-document-multiple-outline",
          allowed: false,
          meta: {
            title: "add_offer",
            permissions: "offers_view",
          },
          redirect: "/offers/:slug/main_information",
          children:[
              ...asyncOfferRoutes
          ]
    
         }
        
      ]
    },
  ],
};
