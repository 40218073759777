export const enableServiceRoutes = {
  path: "/services/:slug/enable",
  to: "/services/:slug/enable",
  component: () => import("@/views/system/services/enable"),

  icon: "enable-service.svg",
  allowed: true,
  order: 4,
  activeRoutes: ["enable_records"],
  meta: {
    permissions: "services_edit",
    title: "enable_records",
  },
};
