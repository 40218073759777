<template>
  <div>
    <v-label>
      <strong class="font-weight-dark"> {{ title }}</strong>
    </v-label>

    <vue-editor
     :class="$attrs['error-messages'].length > 0 ? 'has_error' : ''"
      v-bind="$attrs"
      v-on="$listeners"
      @input="clearServerErrors"
      dir="rtl"
      ref="editor"
      :editorToolbar="customToolbar"
      id="editor"
    >
    </vue-editor>

   
    <div class="error--text" v-if="$attrs['error-messages'].length > 0">
      {{ $attrs["error-messages"][0] }}
    </div>
    <p v-else>
      {{ $t(`hint_${hint}`) }}
    </p>
  </div>
</template>
<script>
import { VueEditor } from "vue2-editor";

export default {
  name: "Editor",
  components: {
    VueEditor,
  },
  props: {
    hint: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    limit: {
      type: Number,
      default: 0,
    },
    form: {
      type: Object,
      default: () => {},
    },
    fieldName: {
      type: String,
      default: "",
    },
  },
  methods: {
    clearServerErrors() {
      if (this.serverErrors) {
        this.$store.dispatch("ClearServerErrors");
      }
    },
  },
  data() {
    return {
      customToolbar: [
        ["bold", "italic", "underline", "strike", "link"],
        [{ align: "" }, { align: "justify" }, { align: "right" }],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        [{ direction: "rtl" }],
      ],
    };
  },
};
</script>
