export const mainInfoRoutes = {
  path: "/services/:slug/main_information",
  to: "/services/:slug/main_information",
  component: () => import("@/views/system/services/mainInfo"),

  icon: "main-info.svg",
  allowed: true,
  order: 2,
  activeRoutes: ["mainInfo_records"],
  meta: {
    permissions: "services_edit",
    title: "mainInfo_records",
  },
};
