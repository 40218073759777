export const mainInfoRoutes = {
  path: "main_informations",
  to: "/dynamic-services/:slug/rental/:id/main_informations",
  component: () => import("@/views/dynamic-service/rental/actions"),
  icon: "main_info.svg",
  allowed: true,
  order: 1,
  activeRoutes: ["mainInfo_records"],
  meta: {
    permissions: "services-rental-services_view",
    title: "main_info_record_rental",
  },
};
