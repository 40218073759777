export const policyRoutes = {
  path: "/offers/:id/policy",
  to: "/offers/:id/policy",
  component: () => import("@/views/main-content/offers/policy/record"),
  icon: "policy.svg",
  allowed: true,
  order : 3,
  activeRoutes: ["policy_records"],
  meta: {
      permissions : "offers-cancellation-policies_view",
      title : "policy_offer",
  }

};