export const formRoutes = {
  path: "/services/:slug/form",
  to: "/services/:slug/form",
  component: () => import("@/views/system/services/form/record"),

  icon: "form.svg",
  allowed: true,
  order: 60,
  activeRoutes: ["term_records"],
  meta: {
    permissions: "services-field-groups_view",
    title: "form",
  },
};
