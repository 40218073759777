export const includeRoutes = {
  path: "includes",
  to: "/dynamic-services/:slug/booking/:id/includes",
  component: () => import("@/views/dynamic-service/booking/include/record"),
  icon: "include.svg",
  allowed: true,
  order : 5,
  activeRoutes: ["include_records"],
  meta: {
      permissions : ["services-booking-models-includes_view","services-booking-models-includes-features_view"],
      title : "includes_models",
  }

};