import axios from "@/plugins/axios";
const languages = {
  state: () => {
    return {
      languages: [],
    };
  },
  mutations: {
    SET_LANGUAGES(state, payload) {
      state.languages = payload;
    },
  },
  actions: {
    async setLanguages({ commit }) {
      const res = await axios.get("languages", {
        params: { pagination: "all" },
      });
      const languages = res.data.data;
      commit("SET_LANGUAGES", languages);
    },
  },

};

export default languages;
