const country = {
  state: () => {
    return {
      phone_available: false,
      target_available: false,
      destination_available: false
    }
  },
  mutations: {
    SET_IS_COUNTRY(state, payload) {
      state.phone_available = payload.phone_available ?? false;
      state.target_available = payload.target_available ?? false;
      state.destination_available = payload.destination_available ?? false
    }
  },
  getters: {
    getCountry: (state) => ({
      phone_available: state.phone_available,
      target_available: state.target_available,
      destination_available: state.destination_available
    })
  }
};

export default country;
