export const orderRoutes = {
  path: "/orders",
  to: "/order",
  icon: "order.svg",
  activeRoutes: ["record_order"],
  meta: {
    title: "orders",
    permissions: "orders_view"
  },
  allow_children: true,
  showInMenu: false,
  order: 5,
  component: () => import("@/views/orders"),
  children: [
    {
      path: "/orders",
      to: "/orders",
      icon: "arrow.svg",
      activeRoutes: ["record_orders", "add_orders", "orders"],
      allowed: true,
      hideInMenu: false,
      allow_children: false,
      meta: {
        title: "all_orders",
        permissions: "orders_view",
      },
      component: () => import("@/views/orders/all"),
      children: [
        {
          path: "",
          component: () => import("@/views/orders/all/record"),
          
          icon: "arrow.svg", // < arrow icon
          to: "/orders",
          allowed: true,
          meta: {
            title: "record_orders",
            permissions: "orders_view",
          },
        },
        {
          path: ":id/show",
          component: () => import("@/views/orders/all/show"),
          
          icon: "mdi-square-show-outline",
          hidden: true,
          meta: {
            title: "show_orders",
            permissions: "orders_view",
          },
        },


      ],
    },


  ],
};
