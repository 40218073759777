export const destinationsRoutes = {
    path: "/countries/:id/destinations",
    component: () => import("@/views/countries/destinations"),
    icon: "arrow.svg", // < arrow icon 
    to: "/countries/:id/destinations",
    hidden: false,
    activeRoutes: ["record_destinations", "add_destination"],
    allowed: true,
    allow_children: true,
    meta: {
      title: "destinations",
      permissions: "destinations_view",
    },
    children: [
      {
        path: "",
        component: () => import("@/views/countries/destinations/record"),
  
        icon: "arrow.svg", // < arrow icon 
        to: "/countries/:id/destinations",
        allow_children: true,
        activeRoutes: ["record_destinations"],
        allowed: true,
        meta: {
          title: "record_destinations",
          permissions: "destinations_view",
        },
      },


      {
        path: "add",
        component: () => import("@/views/countries/destinations/actions"),
  
        icon: "arrow.svg",
        to: "/countries/:id/destinations/add",
        allowed: false,
        meta: {
          title: "add_user",
          permissions: "destinations_create",
        },
      },
      {
        path: ":destination_id/edit",
        to: "/countries/:id/destinations/:country_id/edit",
        component: () => import("@/views/countries/destinations/actions"),
        
        icon: "mdi-square-show-outline",
        hidden: true,
        meta: {
          title: "edit_destinations",
          permissions: "edit_destinations",
        },
      },
      {
        path: ":destination_id/gallery",
        to: "/countries/:id/destinations/:country_id/gallery",
        component: () => import("@/views/countries/destinations/gallery"),
        
        icon: "mdi-square-show-outline",
        hidden: true,
        meta: {
          title: "gallery_destinations",
          permissions: "gallery_destinations",
        },
      },
     

    ]
  };