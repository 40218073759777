<template>
  <div>
    <v-label :for="name">
      <strong class="font-weight-dark"> {{ $t(name) }}</strong>
    </v-label>
    <v-text-field :autofocus="false" v-bind="$attrs" v-on="$listeners" class="mt-1" dense rounded outlined
      :type="inputType" :hint="$t(`hint_${hint ? hint : name}`)" persistent-hint>
      <template slot="append">
        <span v-html="passwordIcon" @click="showPassword = !showPassword"></span>
      </template>
    </v-text-field>
  </div>
</template>
<script>
export default {
  name: "InputTypePassword",
  props: {
    name: {
      type: String,
      default: "",
    },
    hint: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      showPassword: false,
    };
  },
  computed: {
    inputType() {
      return this.showPassword ? "text" : "password";
    },
    passwordIcon() {
      return this.showPassword ? `<i class="fa-solid fa-eye-slash mt-1"></i>` : `<i class="fa-solid fa-eye mt-1"></i>`;
    },
  },
};
</script>
