<template>
    <div class="wrap-switch d-flex align-center justify-md-space-between">
        <label class="size-14">
            <strong>
                {{ active ? $t('deactive') : $t('active') }}
            </strong>
        </label>
        <slot />

    </div>
</template>

<script>
export default {
    name: "WrapSwitch",
    props: ['active']
}
</script>