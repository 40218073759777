export const dashboardRoutes = {
  path: "/services/:slug/dashboard",
  to: "/services/:slug/dashboard",
  component: () => import("@/views/system/services/dashboard"),

  icon: "service-dashboard.svg",
  allowed: true,
  order: 1,
  activeRoutes: ["dashboard_records"],
  meta: {
    permissions: "services_view",
    title: "dashboard_records",
  },
};
