export default {
  state: () => ({
    drawer: true
  }),
  mutations: {
    SET_DRAWER(state, drawer) {
      state.drawer = drawer;
    }
  },
  actions: {
    setDrawer({ commit }, payload) {
      commit("SET_DRAWER", payload);
    }
  },
  getters: {
    getDrawer(state) {
      return state.drawer;
    }
  }
};
