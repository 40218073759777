import axios from "@/plugins/axios";
export default {
  state: () => ({
    booking: {},
  })
  ,
  mutations: {
    SET_BOOKING(state, payload) {
      state.booking = payload
    }
  },
  actions: {
    async setBooking({ commit }, url) {
      const res = await axios.get(url);
      const booking_model = res.data.booking_model;
      commit('SET_BOOKING', booking_model)
      return Promise.resolve();
    }
  },
  getters: {
    getBooking(state) {
      return state.booking
    },
  }
}

