<script>
export default {
  name: "GenericComponent",

  props: {
    label: {
      type: String,
      default: ""
    },
    filterName: {
      type: String,
      default: ""
    },
    form: {
      type: Object,
      default: () => {}
    },
    allowedDates: {
      type: Function,
      default: (date) => {
        return date;
      }
    }
  },
  methods: {
    handleRemoveFilter() {
      const query = { ...this.getQuery };
      this.$delete(query, this.filterName);
      this.$delete(query, "sort[column]");
      this.$delete(query, "sort[dir]");
      this.$eventBus.$emit("on-refresh");
      this.$nextTick(() => {
        delete this.form[this.filterName];
        this.$delete(this.form, this.filterName);
        this.$store.dispatch("setQuery", query);
      });
    },
    handleFilter() {
      const query = { ...this.getQuery, page: 1 };
      for (const key in this.form) {
        if (this.form[key] !== null || this.form[key] !== "") {
          query[key] = this.form[key];
        }
      }
      this.$store.dispatch("setQuery", query);

    }
  },
  computed: {
    valueExit() {
      return this.form[this.filterName] !== "";
    }
  },
  watch: {
    getQuery: {
      handler(query) {
        if (!query) {
          this.$delete(this.form, this.filterName);
          this.show = false;
          setTimeout(() => {
            this.show = true;
          }, 50);
        }
      },
      immediate: true
    }
  }
};
</script>
