import cookie from "js-cookie";
import { setLanguage } from "@/utils/languages";
export default {
  state: () => ({
    locale: cookie.get("language") || "en"
  }),
  mutations: {
    SET_LOCALE(state, locale) {
      state.locale = locale;
    }
  },
  actions: {
    setLocale({ commit, dispatch }, payload) {
      commit("SET_LOCALE", payload);

    }
  },
  getters: {
    getLocale(state) {
      return state.locale;
    }
  }
};
