<template>
  <v-btn v-on="$listeners" v-bind="$attrs">
    <slot />
  </v-btn>
</template>

<script>
export default {
  name: 'Button',
  inheritAttrs: false,
}
</script>
