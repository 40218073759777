export const programRoutes = {
  path: "programs",
  component: () => import("@/views/dynamic-service/booking/program"),
  
  icon: "program.svg", // < arrow icon 
  to: "/dynamic-services/:slug/booking/:id/programs",
  hidden: false,
  activeRoutes: ["record_programs", "add_program"],
  allowed: true,
  allow_children: true,
  order: 7,
  meta: {
    title: "programs",
    permissions: ["services-booking-models-programs_view","services-booking-models-programs-daily-programs_view","services-booking-models-programs-daily-programs-medias_view"],
  },
  children: [
    {
      path: "",
      component: () => import("@/views/dynamic-service/booking/program/record"),
      icon: "arrow.svg", // < arrow icon 
      to: "/dynamic-services/:slug/booking/:id/programs",
      allow_children: true,
      activeRoutes: ["record_programs"],
      allowed: true,
      meta: {
        title: "record_programs",
        permissions: "services-booking-models-programs_view",
      },

    },

    {
      path: "add",
      component: () => import("@/views/dynamic-service/booking/program/actions"),
      icon: "arrow.svg", // < arrow icon 
      allow_children: true,
      activeRoutes: ["record_programs"],
      allowed: true,
      meta: {
        title: "record_programs",
        permissions: "services-booking-models-programs_create",
      },

    },


    {
      path: ":program_id/add",
      component: () => import("@/views/dynamic-service/booking/program/daily/actions"),
      icon: "arrow.svg",
      to: "/dynamic-services/:slug/booking/:id/programs/add",
      allowed: false,
      meta: {
        title: "add_user",
        permissions: "services-booking-models-programs-daily-programs_create",
      },
    },
    {
      path: ":program_id/edit",
      to: "/dynamic-services/:slug/booking/:id/programs/:program_id/edit",

      component: () => import("@/views/dynamic-service/booking/program/actions"),
      
      icon: "mdi-square-show-outline",
      hidden: true,
      meta: {
        title: "edit_programs",
        permissions: "services-booking-models-programs_edit",
      },
    },

    {
      path: ":program_id/daily/:program_daily_id/edit",
      component: () => import("@/views/dynamic-service/booking/program/daily/actions"),
      icon: "arrow.svg",
      allowed: false,
      meta: {
        title: "add_user",
        permissions: "services-booking-models-programs-daily-programs_edit",
      },
    },

    {
      path: ":program_id/daily/:program_daily_id/media",
      component: () => import("@/views/dynamic-service/booking/program/daily/media"),
      
      icon: "mdi-square-show-outline",
      hidden: true,
      meta: {
        title: "media_programs",
        permissions: "services-booking-models-programs-daily-programs_view",
      },
    },



  ]
};