export const phoneCodeRoutes = {
  path: "/countries/:id/phone-code",
  to: "/countries/:id/phone-code",
  icon: "mdi-account-multiple-outline",
  activeRoutes: ["record_phone-code"] ,
  allow_children: false,
//   order : 15,
  showInMenu: true,
  
  meta: {
      title: "view-phone-code",
      permissions: "countries-phone-code",
      
  },
  component: () => import("@/views/countries/phone-code"),
  children: [
      {
          path: "/",
          component: () => import("@/views/countries/phone-code/record"),
          name: "record_phone-code",
          icon: "mdi-file-document-multiple-outline",
          allowed: true,
          meta: {
              title: "record_phone-code",
              permissions: "countries-phone-code_view"
          }
      },
  ],
};
