export const contactRoutes = {
  path: "/contact",
  to: "/contact",
  icon: "contact-white.svg",
  activeRoutes: ["record_contact"],
  meta: {
    title: "contact",
    permissions: ["contact-topics_view","contact-messages_view"]
  },
  
  allow_children: true,
  showInMenu: true,
  order: 5,
  component: () => import("@/views/contact"),
  children: [
    {
      path: "/topics",
      to: "/topics",
      icon: "arrow.svg",
      activeRoutes: ["record_topics", "add_topics", "topics"],
      allowed: true,
      hideInMenu: false,
      allow_children: false,
      
      meta: {
        title: "topics",
        permissions: "contact-topics_view",
      },
      component: () => import("@/views/contact/topics"),
      children: [
        {
          path: "",
          component: () => import("@/views/contact/topics/record/index"),
          
          icon: "arrow.svg", // < arrow icon
          to: "/topics",
          allowed: true,
          meta: {
            title: "record_topics",
            permissions: "topics_view",
          },
        },
      
      
      ],
    },
    {
      path: "/contact-messages",
      to: "/contact-messages",
      icon: "arrow.svg",
      activeRoutes: ["record_messages", "messages"],
      allowed: true,
      hideInMenu: false,
      allow_children: false,
      
      meta: {
        title: "messages",
        permissions: "contact-messages_view",
      },
      component: () => import("@/views/contact/messages"),
      children: [
        {
          path: "",
          component: () => import("@/views/contact/messages/record/index"),
          
          icon: "arrow.svg", // < arrow icon
          to: "/messages",
          allowed: true,
          meta: {
            title: "record_messages",
            permissions: "contact-messages_view",
          },
        },

      ],
    },


  ],
};
