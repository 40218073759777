<template>
  <div>
    <v-label v-if="showLabel" :for="name">
      <strong class="font-weight-dark"> {{ label ? $t(label) : $t(name) }}</strong>
    </v-label>
    <v-select :multiple="multiple" v-bind="$attrs" class="mt-1 fill-field" :items="items" outlined :item-text="itemText"
      :item-value="itemValue" v-on="$listeners" :hint="showHint ? $t(`hint_${hint ? hint : name}`) : ''" :persistent-hint="showHint"
      rounded >
      <template slot="append">
        <span v-html="selectIcon" class="mt-1"></span>
      </template>
      <template v-if="icon" slot="prepend-inner">
        <i  :class="`${icon} mt-1`"></i>
      </template>
      <template #item="{ item }" v-if="hasItemSlot">
        <slot name="item" :item="item" />
      </template>
    </v-select>
  </div>
</template>

<script>
export default {
  name: "InputSelect",
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    name: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "",
    },
    itemValue: {
      type: String,
      default: "id",
    },
    itemText: {
      type: String,
      default: "name",
    },
    label: {
      type: String,
      default: "",
    },
    showHint: {
      type: Boolean,
      default: true,
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    hasItemSlot: {
      type: Boolean,
      default: false,
    },
    hint: {
      type: String,
      default: "",
    },
  },
  methods: {
    clearServerErrors() {
      if (this.serverErrors) {
        this.$store.dispatch("ClearServerErrors");
      }
    },
  },
  computed: {
    selectIcon() {
      return '  <i class="fa fa-chevron-down fa-sm"></i>';
    },
  },
};
</script>
