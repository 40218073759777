<template>
  <v-app>
    <Notification />
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>
<script>
import Cookies from "js-cookie";
import store from "@/store";
import Notification from "@/components/Notification"
export default {
  name: "App",
  components: {
    Notification
  },
  created() {
    if (Cookies.get("token")) {
      store.dispatch("GetUserInfo")
    }
  },
};


</script>
