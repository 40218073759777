import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import ar from "vuetify/lib/locale/ar";
import ChangeDirection from "@/helpers/ChangeDirection";

Vue.use(Vuetify);

export default new Vuetify({
  rtl: ChangeDirection(),
  icons: {
    iconfont: "mdi"
  },
  theme: {
    themes: {
      light: {
        primary: '#0f6ffd',
        secondary: '#99cb3b',
        erorr: '#E10000',
        info: '#2196f3',
        warning: '#fb8c00',
        success: "#5ab805",
        "dark-grey": "#e9e9e9",
        "light-gray": "#F5F5F5"
      },

    }
  },
  lang: {
    current: "ar",
    locales: { ar }
  }
});
