export const mediaRoutes = {
  path: "media",
  to: "/dynamic-services/:slug/rental/:id/media",
  component: () => import("@/views/dynamic-service/rental/media"),
  icon: "setting-img.svg",
  allowed: true,
  order: 2,
  activeRoutes: ["media_records"],
  meta: {
    permissions: "services-rental-models-medias_create",

    title: "setting-img",
  },
};
