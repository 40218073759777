export const termRoutes = {
  path: "/services/:slug/terms",
  to: "/services/:slug/terms",
  component: () => import("@/views/system/services/terms/record"),

  icon: "terms.svg",
  allowed: true,
  order: 9,
  activeRoutes: ["term_records"],
  meta: {
    permissions: "services-term-and-conditions_view",
    title: "terms",
  },
};
