<template>
  <v-card outlined class="px-3 py-2">
    <div>
      <h3 class="heading-card--right-small">
        {{ groupPermssionName }}
      </h3>
      <v-card-text class="pa-0 mt-4 d-flex flex-column gap-10">

        <slot name="permission_body"> </slot>

      </v-card-text>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "PermissionGroup",
  props: {
    groupPermssionName: {
      type: String,
      default: "",
    },
  },
};
</script>
