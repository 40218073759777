export const policyRoutes = {
  path: "policy",
  to: "/dynamic-services/:slug/booking/:id/policy",
  component: () => import("@/views/dynamic-service/booking/policy/record"),
  icon: "policy.svg",
  allowed: true,
  order : 4,
  activeRoutes: ["policy_records"],
  meta: {
      permissions : "services-booking-models-cancellation-policies_view",
      title : "policy_models",
  }

};