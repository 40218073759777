export const priceRoutes = {
  path: "prices",
  component: () => import("@/views/dynamic-service/rental/prices"),
  icon: "prices.svg", // < arrow icon
  to: "/dynamic-services/:slug/rental/:id/prices",
  hidden: false,
  activeRoutes: ["record_prices", "add_price"],
  allowed: true,
  allow_children: true,
  order: 3,
  meta: {
    title: "prices",
    permissions: "services-rental-models-prices_view",
  },
  children: [
    {
      path: "",
      component: () => import("@/views/dynamic-service/rental/prices/record"),

      icon: "arrow.svg", // < arrow icon
      to: "/dynamic-services/:slug/rental/:id/prices",
      allow_children: true,
      activeRoutes: ["record_prices"],
      allowed: true,
      meta: {
        title: "record_prices",
        permissions: "services-rental-models-prices_view",
      },
    },

    {
      path: "add",
      component: () => import("@/views/dynamic-service/rental/prices/actions"),

      icon: "arrow.svg",
      to: "/dynamic-services/:slug/rental/:id/prices/add",
      allowed: false,
      meta: {
        title: "add_user",
        permissions: "services-rental-models-prices_create",
      },
    },
    {
      path: ":price_id/edit",
      to: "/dynamic-services/:slug/rental/:id/prices/:price_id/edit",

      component: () => import("@/views/dynamic-service/rental/prices/actions"),

      icon: "mdi-square-show-outline",
      hidden: true,
      meta: {
        title: "edit_prices",
        permissions: "services-rental-models-prices_edit",
      },
    },
  ],
};
