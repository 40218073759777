export const specificationRoutes = {
  path: "/services/:slug/specification",
  component: () => import("@/views/system/services/specification"),

  icon: "tag.svg", // < arrow icon
  to: "/services/:slug/specification",
  hidden: false,
  activeRoutes: ["record_specifications", "add_specification"],
  allowed: true,
  order: 8,
  allow_children: true,
  meta: {
    title: "specification",
    permissions: [
      "services-booking-specification-groups_view",
      "services-rental-specification-groups_view",
    ],
  },
  children: [
    {
      path: "",
      component: () => import("@/views/system/services/specification/record"),

      icon: "arrow.svg", // < arrow icon
      to: "/services/:slug/specifications",
      allow_children: true,
      activeRoutes: ["record_specifications"],
      allowed: true,
      meta: {
        title: "record_specifications",
        permissions: [
          "services-rental-specification-groups_view",
          "services-booking-specification-groups_view",
        ],
      },
    },

    {
      path: "add",
      component: () => import("@/views/system/services/specification/actions"),

      icon: "arrow.svg", // < arrow icon
      allow_children: true,
      activeRoutes: ["record_specifications"],
      allowed: true,
      meta: {
        title: "record_specifications",
        permissions: [
          "services-rental-specification-groups_create",
          "services-booking-specification-groups_create",
        ],
      },
    },

    {
      path: ":id/edit",
      to: "/services/:slug/specifications/:id/edit",

      component: () => import("@/views/system/services/specification/actions"),

      icon: "mdi-square-show-outline",
      hidden: true,
      meta: {
        title: "edit_specifications",
        permissions: [
          "services-rental-specification-groups_edit",
          "services-booking-specification-groups_edit",
        ],
      },
    },

    {
      path: ":id/keys/add",
      component: () =>
        import("@/views/system/services/specification/key/actions"),

      icon: "arrow.svg",
      to: "/services/:slug/specifications/add",
      allowed: false,
      meta: {
        title: "add_user",
        permissions: [
          "services-rental-specification-groups-specification-keys_create",
          "services-booking-specification-groups-specification-keys_create",
        ],
      },
    },

    {
      path: ":id/keys/:specification_key_id/edit",
      component: () =>
        import("@/views/system/services/specification/key/actions"),

      icon: "arrow.svg",
      allowed: false,
      meta: {
        title: "add_user",
        permissions: [
          "services-rental-specification-groups-specification-keys_edit",
          "services-booking-specification-groups-specification-keys_edit",
        ],
      },
    },
  ],
};
