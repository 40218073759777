import axios from "@/plugins/axios";
export default {
  state: () => ({
    service: {},
  }),
  mutations: {
    SET_SERVICE(state, payload) {
      state.service = payload
    }
  },
  actions: {
    async setService({ commit }, id) {
      const res = await axios.get(`services/${id}`);
      const service = res.data.service;
      commit('SET_SERVICE', service)
      return Promise.resolve();
    }
  },
  getters: {
    getService(state) {
      return state.service
    },
  }
}

