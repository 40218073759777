<template>
  <v-row>
    <v-col cols="12">
      <v-label>
        <span class="font-weight-bold form-label mb-4 primary--text">
          {{ $t(`dashboard.${label}`) }}</span
        >
      </v-label>
      <v-autocomplete
        v-if="is_search"
        append-icon=""
        eager
        persistent-placeholder
        hide-details
        :items="items"
        item-text="name"
        item-value="value"
        outlined
        v-bind="$attrs"
        v-model="form[filterName]"
        @change="handleFilter"
        @click:clear="handleRemoveFilter"
    
        prepend-inner-icon="mdi-magnify"
        :no-data-text="$t('dashboard.public.no_data')"
      >
      </v-autocomplete>
      <v-select
        eager
        v-else
        hide-details
        :items="items"
        item-text="name"
        item-value="value"
        :menu-props="{ bottom: true, offsetY: true }"
        outlined
        v-bind="$attrs"
        v-model="form[filterName]"
        @change="handleFilter"
        @click:clear="handleRemoveFilter"
 
        :no-data-text="$t('dashboard.public.no_data')"
      >
      </v-select>
    </v-col>
  </v-row>
</template>

<script>
import GenericComponent from '../GenericComponent'

export default {
  name: 'SelectFilter',
  extends: GenericComponent,
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    is_search: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {}
  },
}
</script>
