<template>
  <v-container class="service__layout mt-9">
    <v-row>
      <v-col cols="12" lg="3">
        <v-sheet class="py-3 px-1">
          <div class="d-flex align-center justify-space-around">
            <span
              class="primary d-flex align-center justify-center"
              style="width: 60px; height: 60px; border-radius: 50%"
            >
              <img
                v-if="service.icon_image"
                width="30"
                height="30"
                :src="service.icon_image.path"
                alt=""
              />
            </span>
            <div class="px-2" v-if="service">
              <h4>{{ service.name }}</h4>
              <p
                v-if="service.description"
                class="size-12 elipses service__layout__description"
              >
                {{ service.description }}
              </p>
              <Button outlined rounded v-if="service.type">
                {{ $t(`service_${service.type}`) }}</Button
              >
            </div>
          </div>
        </v-sheet>
        <v-sheet class="mt-3 h-100 mb-6">
          <v-list dense class="d-flex flex-row tabs transparent py-0 mb-5">
            <v-list-item-group
              v-model="selectedItem"
              color="primary"
              class="w-100"
            >
              <div v-for="(item, i) in getServiceRoutes" :key="i">
                <v-list-item
                v-if="
               
               service.type == 'form' && item.meta.title == 'specification'
                 ? false
                 : true  &&  hasPermission(item.meta.permissions)
             "
                  class="d-flex align-center"
                  :to="`/${item.to.split('/')[1]}/${$route.params.slug}/${
                    item.to.split('/')[3]
                  }`"
                >
                  <img
                    :src="require(`@/assets/imgs/icons/${item.icon}`)"
                    width="20"
                    height="20"
                    :alt="item.meta.title"
                  />
                  <v-list-item-content class="mx-3">
                    <v-list-item-title class="size-16">{{
                      $t(item.meta.title)
                    }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </div>
            </v-list-item-group>
          </v-list>
        </v-sheet>
      </v-col>
      <v-col cols="12" lg="9">
        <v-toolbar flat class="white service__toolbar">
          <Heading
            title="add_service"
            :nested="` > ${service?.name} > ${$t(currentTitle)}`"
            description="can_create_service"
            :dynamicDescription="`${$t(currentPath + '_description')}`"
            imageName="create-service.svg"
            width="20"
            height="20"
          ></Heading>
        </v-toolbar>
        <v-card class="mt-4 h-100" elevation="0">
          <router-view></router-view>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "ServiceLayout",
  computed: {
    ...mapGetters({
      getServiceRoutes: "getServiceRoutes",
      service: "getService",
    }),
    currentPath() {
      let path = this.$route.path.split("/");
      let description = path[path.length - 1];
      if (description == "form") {
        return description;
      } else {
        return "can_create_service";
      }
    },
    currentTitle() {
      let path = this.$route.path.split("/");
      let title = path[path.length - 1];
      return title;
    },
  },
  data() {
    return {
      selectedItem: 1,
    };
  },
  methods: {
    async fetchService() {
      await this.$store.dispatch("setService", this.$route.params.slug);
    },
  },

  mounted() {
    this.fetchService();
  },
};
</script>
