<template>
  <div>
    <v-label :for="name">
      <strong class="font-weight-dark"> {{ $t(name) }}</strong>
    </v-label>
    <v-text-field rounded outlined flat type="number" v-bind="$attrs" class="mt-1 fill-field" filled v-on="$listeners"
    :hint="showHint ? $t(`hint_${hint ? hint : name}`) : ''"  persistent-hint>
    </v-text-field>
  </div>
</template>
  
<script>
export default {
  name: "InputTypeNumber",
  props: {
    name: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    hint: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    showHint: {
      type: Boolean,
      default: true,
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
  },
};
</script>