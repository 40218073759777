import Vue from "vue";
import { mapGetters } from "vuex";
export const globalMixin = {
  computed: {
    ...mapGetters({
      getPermissions: "getPermissions",
      pagination: "getPagination",
      user: "getUser",
      getQuery: "getQuery",
      getLocale: "getLocale"
    }),
    serverErrors() {
      return this.$store.getters.serverErrors;
    },
    checkPermissionForCurrentRoute() {
      return this.checkPermissionManually(this.$route.meta.permissions);
    }
  },
  methods: {
    hasPermission(permissionValidate) {
      const userPermissions = this.getPermissions
        .map((permission) =>
          permission.sub_permissions.map(
            (subPermission) =>
              subPermission.name.replaceAll(/[/ ]+/g, "-").toLowerCase()
          )
        )
        .flat();

      if (Array.isArray(permissionValidate)) {
        return permissionValidate.some(permission =>
          userPermissions.includes(permission)
        );
      } else {
        return userPermissions.includes(permissionValidate);
      }
    },
    dateFormat(date) {
      const dateFormat = new Date(date);
      const dateHuman = new Intl.DateTimeFormat(this.$i18n.locale, {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        timeZone: 'UTC'
      }).format(dateFormat);
      return dateHuman;
    },
    checkPermissionManually(permissionName) {
      let mainPermission = permissionName.split("_")[0];
      let permissionCurrentRoute = this.getPermissions?.find(permission => permission.name.replaceAll(/[/ ]+/g, "-").toLowerCase() == mainPermission) || null;
      let hasPermission = {
        view: false,
        create: false,
        edit: false,
        delete: false,
      };
      if (!permissionCurrentRoute) return hasPermission;
      console.log(permissionCurrentRoute);
      permissionCurrentRoute.sub_permissions.forEach(subPermission => {
        let subPermissionName = subPermission.name.split("_")[1];
        hasPermission[subPermissionName] = true;
      })
      console.log(hasPermission);
      return hasPermission;
    },
    date(value) {
      let date = value.split("T")[0]
      return date
    },
    formattedDate(date) {
      const inputDate = new Date(date);
      const options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
      };
      return inputDate.toLocaleDateString(this.$i18n.locale, options);
    },
  },
  filters: {
    truncate(value, count) {
      if (value && value.length > count) {
        return value && value.substring(0, count) + "...";
      } else {
        return value;
      }
    }
  }
};

Vue.mixin(globalMixin);
