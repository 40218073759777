<template>
  <v-dialog :persistent="persistent" :max-width="maxWidth" :value="dialog" class="generic-dialog rounded-lg"
    @input="$emit('update:dialog')" @click:outside="$emit('close-dialog')">
    <v-card :max-width="maxWidth" :class="`pb-2  ${center ? 'text-center' : ''} rounded-lg`">
      <div class="generic-dialog__border"></div>
      <v-card-text :class="`pt-5 px-5 ${bgTitle}`">
        <h2 :class="`text-center font-weight-bold `">
          {{ $t(title) }}
        </h2>
        <div v-if="icon">
          <img class="my-6" width="80" height="80" :src="require(`/src/assets/imgs/icons/${icon}`)" />
        </div>
        <p v-if="description" class="px-10 mb-0">
          {{ $t(description) }}
        </p>
      </v-card-text>
      <v-card-actions :class="`px-0 ${justify}`">
        <slot />
      </v-card-actions>
      <p class="size-12">
        {{ $t(hint) }}
      </p>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "GenericDialog",
  inheritAttrs: false,
  props: {
    dialog: {
      default: false,
      type: Boolean,
    },
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    hint: {
      type: String,
      default: "",
    },
    maxWidth: {
      type: String,
      default: "400",
    },
    bgTitle: {
      type: String,
      default: "400",
    },
    icon: {
      type: String,
      default: "",
    },
    justify: {
      type: String,
      default: "justify-center",
    },
    center: {
      type: Boolean,
      default: true,
    },
    persistent: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
