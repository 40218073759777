export const publicRoutes = {
  path: "/settings/public",
  to: "/settings/public",
  component: () => import("@/views/settings/main-info/record"),
  icon: "offer.svg",
  allowed: true,
  order : 2,
  activeRoutes: ["mainInfo_records"],
  meta: {
      permissions : "settings_mainInfo",
      title : "public_setting",
  }
};