<template>
  <v-container class="service__layout mt-6 px-4">
    <v-row>
      <v-col cols="12" lg="3">
        <v-sheet class="mt-3 h-100 mb-6">
          <v-list dense class="d-flex flex-row tabs transparent py-0 mb-5">
            <v-list-item-group
              v-model="selectedItem"
              color="primary"
              class="w-100"
            >
              <div v-for="(item, i) in getBookingRoutes" :key="i">
                <v-list-item
                  v-if="hasPermission(item.meta.permissions)"
                  class="d-flex align-center"
                  :to="`/${item.to.split('/')[1]}/${
                    $route.params.slug
                  }/booking/${$route.params.id}/${item.to.split('/')[5]}`"
                >
                  <img
                    :src="require(`@/assets/imgs/icons/${item.icon}`)"
                    width="20"
                    height="20"
                    :alt="item.name"
                  />
                  <v-list-item-content class="mx-3">
                    <v-list-item-title class="size-16">{{
                      $t(item.meta.title)
                    }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </div>
            </v-list-item-group>
          </v-list>
        </v-sheet>
      </v-col>
      <v-col class="mt-3" cols="12" lg="9">
        <v-toolbar flat class="white service__toolbar">
          <Heading
            title="all_packages"
            :nested="` > ${booking?.name} > ${$t(currentTitle)}`"
            description="all_packages"
            :dynamicDescription="`${$t(currentPath + '_description_booking')}`"
            imageName="create-service.svg"
            width="20"
            height="20"
          ></Heading>
        </v-toolbar>
        <v-card class="mt-4 h-100" elevation="0">
          <router-view></router-view>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "BookingLayout",
  computed: {
    ...mapGetters({
      getBookingRoutes: "getBookingRoutes",
      booking: "getBooking",
    }),
    currentPath() {
      let path = this.$route.path.split("/");
      let description = path[path.length - 1];
      if (description == "form") {
        return description;
      } else {
        return "all_packages";
      }
    },
    currentTitle() {
      let path = this.$route.path.split("/");
      let title = path[5];
      if (title == "policy" || title == "includes" || title == "excludes") {
        title = title + "_models";
      }
      return title;
    },
  },
  data() {
    return {
      selectedItem: 1,
    };
  },
  methods: {
    async fetchBooking() {
      await this.$store.dispatch(
        "setBooking",
        `services/${this.$route.params.slug}/booking-models/${this.$route.params.id}`
      );
    },
  },

  mounted() {
    this.fetchBooking();
  },
};
</script>

<style lang="scss" scoped>
.v-list-item-group .v-list-item--active {
  background-color: var(--primary);
  color: white;

  svg {
    fill: #fff;
  }
}
</style>
