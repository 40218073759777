export const featureRoutes = {
  path: "features",
  to: "/dynamic-services/:slug/booking/:id/features",
  component: () => import("@/views/dynamic-service/booking/features/actions"),
  icon: "all_features.svg",
  allowed: true,
  order : 9,
  activeRoutes: ["feature_records"],
  meta: {
      permissions : "booking_feature",
      title : "features",
  }

};