export const termRoutes = {
  path: "/offers/:id/terms",
  to: "/offers/:id/terms",
  component: () => import("@/views/main-content/offers/terms/record"),
  icon: "terms.svg",
  allowed: true,
  order : 2,
  activeRoutes: ["term_records"],
  meta: {
      permissions : "offers-term-and-conditions_view",
      title : "terms",
  }

};