<template>
  <div>
    <v-label :for="name">
      <strong class="font-weight-dark"> {{ $t(title ? title : name) }}</strong>
    </v-label>
    <v-textarea :placeholder="$t(placeholder)" auto-grow variant="filled" color="primary" :rows="rows" v-bind="$attrs" class="mt-1 fill-field" outlined
      rounded v-on="$listeners" :hint="showHint ? $t(`hint_${hint ? hint : name}`) : ''" persistent-hint>
    </v-textarea>
  </div>
</template>

<script>
export default {
  name: "InputTextarea",
  props: {
    rows: {
      type: String,
      default: "1",
    },
    name: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    hint: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    showHint: {
      type: Boolean,
      default: true,
    },
  },
};
</script>