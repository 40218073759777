export const destinationRoutes = {
  path: "/countries/:id/destination",
  to: "/countries/:id/destination",
  icon: "mdi-account-multiple-outline",
  activeRoutes: ["record_destination"] ,
  allow_children: false,
//   order : 16,
  showInMenu: true,
  
  meta: {
      title: "view-destination",
      permissions: "destination_view",
      
  },
  component: () => import("@/views/countries/destination"),
  children: [
      {
          path: "/",
          component: () => import("@/views/countries/destination/record"),
          
          icon: "mdi-file-document-multiple-outline",
          allowed: true,
          meta: {
              title: "record_destination",
              permissions: "countries-destination_view"
          }
      },
  ],
};
