import vue from "vue";
import FormGroup from "@/components/FormGroup";
import ShimmerLoader from "@/components/ShimmerLoader";
import CustomTable from "@/components/CustomTable";
import Pagination from "@/components/Pagination";
import ToolTip from "@/components/ToolTip";
import Button from "@/components/Button";
import FiltersTextInput from "@/components/Filters/TextInput";
import FiltersDatePicker from "@/components/Filters/DatePicker";
import FiltersSelect from "@/components/Filters/Select";
import FormsTextInput from "@/components/Forms/TextInput";
import AutoComplete from "@/components/Forms/AutoComplete";
import DropDown from "@/components/Forms/DropDown";
import FormsTextArea from "@/components/Forms/TextArea";
import GenericDialog from "@/components/GenericDialog";
import LocaleSelector from "@/components/LocaleSelector";
import Editor from "@/components/Editor";
import CustomToggle from "@/components/CustomToggle";
import FormsColorPicker from "@/components/Forms/ColorPicker";
import PermissionGroup from "@/components/PermissionGroup";
import InputText from "@/components/Input/Text";
import InputFile from "@/components/Input/File";
import InputPassword from "@/components/Input/Password";
import InputTextArea from "@/components/Input/TextArea";
import InputSelect from "@/components/Input/Select"
import InputBoolean from "@/components/Input/Boolean"
import InputPhone from "@/components/Input/Phone";
import InputCounter from "@/components/Input/Counter";
import InputDate from "@/components/Input/Date";
import InputTime from "@/components/Input/Time";
import InputNumber from "@/components/Input/Number";
import ButtonGroup from "@/components/ButtonGroup";
import ActiveBtn from "@/components/ActiveBtn";
import Tabs from "@/components/Tabs";
import Heading from "@/components/Heading";
import ToggleService from "@/components/ToggleService";
import CountryCard from "@/components/Country/Card";
import IntroInfo from "@/components/IntroInfo"
import CardInfo from "@/components/CardInfo"
import WrapSwitch from "@/components/WrapSwitch"
import FileUpload from "@/components/FileUpload"
import TogglePopupService from "@/components/TogglePopupService"
import Loading from "@/components/Loading"
// components
vue.component("FormGroup", FormGroup);
vue.component("ToolTip", ToolTip);
vue.component("ShimmerLoader", ShimmerLoader);
vue.component("CustomTable", CustomTable);
vue.component("Pagination", Pagination);
vue.component("Button", Button);
vue.component("Button", Button);
vue.component("FiltersTextInput", FiltersTextInput);
vue.component("FiltersDatePicker", FiltersDatePicker);
vue.component("FiltersSelect", FiltersSelect);
vue.component("FormsTextInput", FormsTextInput);
vue.component("FormsColorPicker", FormsColorPicker);
vue.component("AutoComplete", AutoComplete);
vue.component("DropDown", DropDown);
vue.component("FormsTextArea", FormsTextArea);
vue.component("GenericDialog", GenericDialog);
vue.component("locale-selector", LocaleSelector);
vue.component("Editor", Editor);
vue.component("custom-toggle", CustomToggle);
vue.component("PermissionGroup", PermissionGroup);
vue.component("InputText" , InputText)
vue.component("InputFile" , InputFile)
vue.component("InputPassword" , InputPassword);
vue.component("InputTextArea" ,InputTextArea);
vue.component("InputSelect" , InputSelect);
vue.component("InputPhone" , InputPhone);
vue.component("InputCounter" , InputCounter);
vue.component("InputDate" , InputDate);
vue.component("InputTime" , InputTime);
vue.component("InputBoolean" , InputBoolean);
vue.component("InputNumber" , InputNumber)
vue.component("ButtonGroup" , ButtonGroup);
vue.component("ActiveBtn" , ActiveBtn)
vue.component("Tabs", Tabs);
vue.component("Heading", Heading);
vue.component("ToggleService", ToggleService);
vue.component('country-card' , CountryCard)
vue.component('introInfo' , IntroInfo)
vue.component('CardInfo' , CardInfo)
vue.component('WrapSwitch' , WrapSwitch)
vue.component('FileUpload' , FileUpload)
vue.component("TogglePopupService", TogglePopupService);
vue.component("Loading", Loading);