export const specificationRoutes = {
  path: "specification",
  to: "/dynamic-services/:slug/rental/:id/specification",
  component: () =>
    import("@/views/dynamic-service/rental/specifications/record"),
  icon: "specs.svg",
  allowed: true,
  order: 6,
  activeRoutes: ["specification_records"],
  meta: {
    permissions: "services-rental-models-specifications_view",
    title: "specification",
  },
};
