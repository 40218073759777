<template>
  <v-color-picker
    v-bind="$attrs"
    v-on="$listeners"
    @update:color="clearServerErrors"
  ></v-color-picker>
</template>

<script>
export default {
  name: "ColorPicker",
  methods: {
    clearServerErrors() {
      if (this.serverErrors) {
        this.$store.dispatch("ClearServerErrors");
      }
    },
  },
};
</script>
