import api from "@/plugins/axios";

export function updateToggle({ name, id, visible, locale, type }) {
  let data;
  data = { _method: "put", [type]: visible, locale };
  return api({
    url: `/${name}/${id}`,
    method: "post",
    data,
  });
}
