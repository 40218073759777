var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"service__layout mt-9"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"3"}},[_c('v-sheet',{staticClass:"py-3 px-1"},[_c('div',{staticClass:"d-flex align-center justify-space-around"},[_c('span',{staticClass:"primary d-flex align-center justify-center",staticStyle:{"width":"60px","height":"60px","border-radius":"50%"}},[(_vm.service.icon_image)?_c('img',{attrs:{"width":"30","height":"30","src":_vm.service.icon_image.path,"alt":""}}):_vm._e()]),(_vm.service)?_c('div',{staticClass:"px-2"},[_c('h4',[_vm._v(_vm._s(_vm.service.name))]),(_vm.service.description)?_c('p',{staticClass:"size-12 elipses service__layout__description"},[_vm._v(" "+_vm._s(_vm.service.description)+" ")]):_vm._e(),(_vm.service.type)?_c('Button',{attrs:{"outlined":"","rounded":""}},[_vm._v(" "+_vm._s(_vm.$t(`service_${_vm.service.type}`)))]):_vm._e()],1):_vm._e()])]),_c('v-sheet',{staticClass:"mt-3 h-100 mb-6"},[_c('v-list',{staticClass:"d-flex flex-row tabs transparent py-0 mb-5",attrs:{"dense":""}},[_c('v-list-item-group',{staticClass:"w-100",attrs:{"color":"primary"},model:{value:(_vm.selectedItem),callback:function ($$v) {_vm.selectedItem=$$v},expression:"selectedItem"}},_vm._l((_vm.getServiceRoutes),function(item,i){return _c('div',{key:i},[(
             
             _vm.service.type == 'form' && item.meta.title == 'specification'
               ? false
               : true  &&  _vm.hasPermission(item.meta.permissions)
           )?_c('v-list-item',{staticClass:"d-flex align-center",attrs:{"to":`/${item.to.split('/')[1]}/${_vm.$route.params.slug}/${
                  item.to.split('/')[3]
                }`}},[_c('img',{attrs:{"src":require(`@/assets/imgs/icons/${item.icon}`),"width":"20","height":"20","alt":item.meta.title}}),_c('v-list-item-content',{staticClass:"mx-3"},[_c('v-list-item-title',{staticClass:"size-16"},[_vm._v(_vm._s(_vm.$t(item.meta.title)))])],1)],1):_vm._e()],1)}),0)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","lg":"9"}},[_c('v-toolbar',{staticClass:"white service__toolbar",attrs:{"flat":""}},[_c('Heading',{attrs:{"title":"add_service","nested":` > ${_vm.service?.name} > ${_vm.$t(_vm.currentTitle)}`,"description":"can_create_service","dynamicDescription":`${_vm.$t(_vm.currentPath + '_description')}`,"imageName":"create-service.svg","width":"20","height":"20"}})],1),_c('v-card',{staticClass:"mt-4 h-100",attrs:{"elevation":"0"}},[_c('router-view')],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }