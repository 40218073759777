<template>
  <v-btn-toggle v-bind="$attrs" v-on="$listeners" background-color="transparent" :multiple="multiple"
    @change="($event) => $emit('input', $event)">
    <v-btn style="width: 70px;" v-for="(item, i) in items" :key="i" depressed :ripple="false" class="mx-2" :value="valueText ? item :  item[id]">
      {{ valueText ? item : item.name  }}
    </v-btn>
  </v-btn-toggle>
</template>

<script>
export default {
  name: "ButtonGroup",
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    id: {
      type: String,
      default: "id",
    },
    multiple: {
      type: [Boolean, Number],
      default: true,
    },
    valueText: {
      type: Boolean,
      default: false,
    },
  },

};
</script>
