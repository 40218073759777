export const settingImgRoutes = {
  path: "/services/:slug/setting-img",
  to: "/services/:slug/setting-img",
  component: () => import("@/views/system/services/setting-img/record"),
  icon: "setting-img.svg",
  allowed: true,
  order: 7,
  activeRoutes: ["setting_records_img"],
  meta: {
    permissions: "services_edit",
    title: "setting",
  },
};
