export const featureRoutes = {
  path: "features",
  to: "/dynamic-services/:slug/rental/:id/features",
  component: () => import("@/views/dynamic-service/rental/features/actions"),

  icon: "all_features.svg",
  allowed: true,
  order: 4,
  activeRoutes: ["feature_records"],
  meta: {
    permissions: "services-rental-models_edit",
    title: "features",
  },
};
