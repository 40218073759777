const asyncRentalRoutes = [];
const context = require.context('./rentalModules', false, /\.js$/);
context.keys().forEach((key) => {
  let fileName = key.replace(/(\.\/|\.js)/g, "");
  asyncRentalRoutes.push(context(key)[fileName + "Routes"]);
});

asyncRentalRoutes.sort((routeA, routeB) => {
  const orderA = routeA.order || 0;
  const orderB = routeB.order || 0;
  return orderA - orderB;
});


export {asyncRentalRoutes}
