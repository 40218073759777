<template>
  <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :nudge-right="40" :return-value.sync="time"
    transition="scale-transition" offset-y max-width="290px" min-width="290px">
    <template v-slot:activator="{ on, attrs }">
      <v-label v-if="showLabel" :for="name">
        <strong class="font-weight-dark"> {{ $t( label? label :name) }}</strong>
      </v-label>
      <v-text-field class="input-date" v-model="form[name]" readonly v-bind="attrs" v-on="on">
        <template #prepend-inner>
          <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M18.094 0c.48 0 .877.362.93.828l.007.11v.937h1.219a3.755 3.755 0 0 1 3.745 3.551l.005.199v5.344a.937.937 0 0 1-1.869.11l-.006-.11V5.625c0-.985-.763-1.794-1.729-1.87l-.146-.005h-1.219v.938a.937.937 0 0 1-1.868.109l-.007-.11V3.75h-4.265v.938a.937.937 0 0 1-1.87.109l-.005-.11V3.75h-4.22v.938a.937.937 0 0 1-1.868.109l-.006-.11V3.75H3.75c-.985 0-1.794.763-1.87 1.729l-.005.146V20.25c0 .985.763 1.794 1.729 1.87l.146.005h7.172a.937.937 0 0 1 .11 1.869l-.11.006H3.75a3.755 3.755 0 0 1-3.745-3.551L0 20.25V5.625A3.755 3.755 0 0 1 3.551 1.88l.199-.005h1.172V.938A.937.937 0 0 1 6.79.828l.006.11v.937h4.219V.938a.937.937 0 0 1 1.868-.11l.007.11v.937h4.265V.938c0-.518.42-.938.938-.938zm.234 12.656A5.678 5.678 0 0 1 24 18.328 5.678 5.678 0 0 1 18.328 24a5.678 5.678 0 0 1-5.672-5.672 5.678 5.678 0 0 1 5.672-5.672zm0 1.875a3.801 3.801 0 0 0-3.797 3.797 3.801 3.801 0 0 0 3.797 3.797 3.801 3.801 0 0 0 3.797-3.797 3.801 3.801 0 0 0-3.797-3.797zm0 .938c.48 0 .877.362.931.828l.007.11v.984h.422a.937.937 0 0 1 .109 1.868l-.11.007h-1.359a.938.938 0 0 1-.931-.829l-.006-.109v-1.922c0-.518.42-.937.937-.937zM5.86 17.063a.937.937 0 1 1 0 1.875.937.937 0 0 1 0-1.875zm4.079 0a.937.937 0 1 1 0 1.874.937.937 0 0 1 0-1.874zm0-4.079a.937.937 0 1 1 0 1.875.937.937 0 0 1 0-1.875zm-4.079 0a.937.937 0 1 1 0 1.875.937.937 0 0 1 0-1.875zm12.235-4.078a.938.938 0 1 1 0 1.875.938.938 0 0 1 0-1.875zm-4.078 0a.938.938 0 1 1 0 1.875.938.938 0 0 1 0-1.875zm-8.157 0a.938.938 0 1 1 0 1.875.938.938 0 0 1 0-1.875zm4.079 0a.937.937 0 1 1 0 1.875.937.937 0 0 1 0-1.875z"
              fill="#0F6FFD" fill-rule="evenodd" />
          </svg>

        </template>
      </v-text-field>
    </template>
    <v-time-picker v-if="menu" v-model="form[name]" w-100 @click:minute="$refs.menu.save(time)"></v-time-picker>
  </v-menu>
</template>
<script>
export default {
  data: () => ({
    menu: false,
    time: null,
  }),
  props: {
    form: {
      type: Object,
      default: () => { },
    },
    name: {
      type: String,
      default: () => "",
    },
    label: {
      type: String,
      default: () => "",
    },
    showLabel: {
      type: Boolean,
      default: () => true,
    },

  },
};
</script>