import { asyncBookingRoutes } from "@/router/bookingRoutes";
import bookingLayout from "@/layout/bookingLayout";
import { asyncRentalRoutes } from "@/router/rentalRoutes";
import rentalLayout from "@/layout/rentalLayout";

export const dynamicServicesRoutes = {
  path: "/dynamic-services",
  to: "/dynamic-services",
  icon: "model-white.svg",
  activeRoutes: ["record_dynamic"],
  meta: {
    title: "dynamic_service",
    permissions: "dynamic_services",
  },
  allow_children: false,
  showInMenu: false,
  component: () => import("@/views/dynamic-service"),
  children: [
    {
      path: "",
      component: () => import("@/views/dynamic-service"),
      name: "record_dynamic-services",
      icon: "arrow.svg", // < arrow icon
      to: "/dynamic-services",
      hidden: true,
      activeRoutes: ["record_dynamic-services", "add_serve"],
      allowed: true,
      allow_children: true,
      meta: {
        title: "dynamic-services",
        permissions: "models",
      },
      children: [
        {
          path: ":slug/categories",
          component: () => import("@/views/dynamic-service/categories"),
          icon: "arrow.svg", // < arrow icon
          to: "/dynamic-services/:slug/categories",
          hidden: false,
          activeRoutes: [
            "record_dynamic-services_categories",
            "add_dynamic-services_category",
          ],
          allowed: true,
          allow_children: true,
          meta: {
            title: "dynamic-services_categories",
            permissions: "services-categories_view",
          },
          children: [
            {
              path: "",
              component: () =>
                import("@/views/dynamic-service/categories/record"),
              icon: "arrow.svg", // < arrow icon
              to: "/dynamic-services/categories",
              allowed: true,
              meta: {
                title: "record_cats",
                permissions: "services-categories_view",
              },
            },

            {
              path: "add",
              component: () =>
                import("@/views/dynamic-service/categories/actions"),

              icon: "arrow.svg",
              to: "/dynamic-services/categories/add",
              allowed: false,
              meta: {
                title: "add_category",
                permissions: "services-categories_create",
              },
            },
            {
              path: ":id/edit",
              component: () =>
                import("@/views/dynamic-service/categories/actions"),
              name: "edit_dynamic-services_categories",
              icon: "mdi-square-show-outline",
              hidden: true,
              meta: {
                title: "edit_dynamic-services_categories",
                permissions: "services-categories_edit",
              },
            },
          ],
        },

        {
          path: ":slug/booking",
          component: () => import("@/views/dynamic-service/booking"),
          icon: "arrow.svg", // < arrow icon
          to: "/dynamic-services/:slug/booking",
          hidden: false,
          activeRoutes: [
            "record_dynamic-services_booking",
            "add_dynamic-services_category",
          ],
          allowed: true,
          allow_children: true,
          meta: {
            title: "dynamic-services_booking",
            permissions: "booking",
          },
          children: [
            {
              path: "",
              component: () => import("@/views/dynamic-service/booking/record"),
              icon: "arrow.svg", // < arrow icon
              to: "/dynamic-services/booking",
              allowed: true,
              meta: {
                title: "record_cats",
                permissions: "dynamic-services_cats_view",
              },
            },

            {
              path: "add",
              component: () =>
                import("@/views/dynamic-service/booking/actions"),

              icon: "arrow.svg",
              to: "/dynamic-services/booking/add",
              allowed: false,
              meta: {
                title: "add_category",
                permissions: "dynamic-services_booking_create",
              },
            },

            {
              path: ":id",
              component: bookingLayout,
              icon: "mdi-file-document-multiple-outline",
              allowed: false,
              redirect: "/bookings/:slug/main_information",
              children: [...asyncBookingRoutes],
            },
          ],
        },

        {
          path: ":slug/rental",
          component: () => import("@/views/dynamic-service/rental"),

          icon: "arrow.svg", // < arrow icon
          to: "/dynamic-services/:slug/rental",
          hidden: false,
          activeRoutes: [
            "record_dynamic-services_rental",
            "add_dynamic-services_category",
          ],
          allowed: true,
          allow_children: true,
          meta: {
            title: "dynamic-services_rental",
            permissions: "rental",
          },
          children: [
            {
              path: "",
              component: () => import("@/views/dynamic-service/rental/record"),
              icon: "arrow.svg", // < arrow icon
              to: "/dynamic-services/rental",
              allowed: true,
              meta: {
                title: "record_cats",
                permissions: "dynamic-services_cats_view",
              },
            },

            {
              path: "add",
              component: () => import("@/views/dynamic-service/rental/actions"),

              icon: "arrow.svg",
              to: "/dynamic-services/rental/add",
              allowed: false,
              meta: {
                title: "add_category",
                permissions: "dynamic-services_rental_create",
              },
            },

            {
              path: ":id",
              component: rentalLayout,
              icon: "mdi-file-document-multiple-outline",
              allowed: false,
              redirect: "/rentals/:slug/main_information",
              children: [...asyncRentalRoutes],
            },
          ],
        },
      ],
    },
  ],
};
