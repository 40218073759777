export const termRoutes = {
  path: "terms",
  to: "/dynamic-services/:slug/booking/:id/terms",
  component: () => import("@/views/dynamic-service/booking/terms/record"),
  
  icon: "terms.svg",
  allowed: true,
  order : 3,
  activeRoutes: ["term_records"],
  meta: {
      permissions : "services-booking-models-term-and-conditions_view",
      title : "terms",
  }

};