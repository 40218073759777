import Vue from "vue";
import Router from "vue-router";
import Layout from "@/layout";
import cookie from "js-cookie";
import { asyncRoutes } from "./asyncRoutes";

Vue.use(Router);

export let constantRouterMap = [
  {
    path: "/",
    component: Layout,
    props: true,
    meta: {
      title: "home"
    },
    children: [
      {
        path: "/home",
        component: () => import("@/views/home"),
        name: "home",
        meta: {
          icon: "mdi-home-variant-outline",
          title: "home"
        }
      },
      {
        path: "/profile",
        component: () => import("@/views/profile/index.vue"),
        name: "profile",
        meta: {
          title: "profile"
        }
      },
      ...asyncRoutes
    ]
  },
  {
    path: "/login",
    component: () => import("@/views/login/index"),
    meta: {
      title: "login"
    },
    hidden: true
  },
  {
    path: "/forget-password",
    component: () => import("@/views/forget-password/index"),
    meta: {
      title: "forget-password"
    },
    hidden: true
  },
  {
    path: "/send-mail-password",
    component: () => import("@/views/send-mail-password/index"),
    meta: {
      title: "send-mail-password"
    },
    hidden: true
  },
  {
    path: "/reset-password",
    component: () => import("@/views/reset-password/index"),
    meta: {
      title: "reset-password"
    },
    hidden: true
  },
  {
    path: "/401",
    component: () => import("@/views/errorPage/401"),
    name: "Page401",
    meta: {
      title: "Page401"
    },
    hidden: true
  },
  {
    path: "/not-found",
    component: () => import("@/views/errorPage/404"),
    name: "404",
    meta: {
      title: "404"
    },
    hidden: true
  },
  {
    path: "*",
    component: () => import("@/views/errorPage/404"),
    name: "Page404",
    meta: {
      title: "Page404"
    },
    hidden: true
  }
];

let router = new Router({
  mode: "history",
  routes: constantRouterMap,
});

router.beforeEach((to, from, next) => {
  const publicPaths = [
    "/login",
    "/verify-code",
    "/verification-method",
    "/reset",
    "/forget-password",
    "/send-mail-password",
    "/reset-password"
  ];

  const isAuthenticated = cookie.get("token");
  const isPublicPath = publicPaths.includes(to.path);

  if (isAuthenticated && isPublicPath) {
    next("/home");
  } else if (!isAuthenticated && !isPublicPath) {
    next("/login");
  } else {
    next();
  }
});


export default router;
