export const statementsRoutes = {
  path: "/statements",
  to: "/statements",
  icon: "statement-white.svg",
  activeRoutes: ["record_statement"],
  meta: {
    title: "statements",
    permissions: ["daily-statements_view"]
  },
  allow_children: true,
  showInMenu: true,
  order: 15,
  component: () => import("@/views/statements"),
  children: [
    {
      path: "/statements",
      to: "/statements",
      icon: "arrow.svg",
      activeRoutes: ["record_statements", "add_statements", "statements"],
      allowed: true,
      hideInMenu: false,
      allow_children: false,
      meta: {
        title: "all_statements",
        permissions: "daily-statements_view",
      },
      component: () => import("@/views/statements"),
      children: [
        {
          path: "",
          component: () => import("@/views/statements/record"),
          
          icon: "arrow.svg", // < arrow icon
          to: "/statements",
          allowed: true,
          meta: {
            title: "record_statements",
            permissions: "daily-statements_view",
          },
        },
        {
          path: ":id/show",
          component: () => import("@/views/statements/show"),
          
          icon: "mdi-square-show-outline",
          hidden: true,
          meta: {
            title: "show_statements",
            permissions: "daily-statements_view",
          },
        },


      ],
    },


  ],
};
