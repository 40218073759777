<template>
    <Button depressed large rounded outlined class="pa-5 w-100 px-0 d-flex align-center justify-space-between" :width="width"
        style="transition: all 0.1s ease" :class="PhoneActive" :style="{
            border: form[name]
                ? ''
                : '1px solid #333 !important',
        }" :value="form[name]" @click="form[name] = !form[name]">
        <span class="d-flex align-center">
            <span class="d-flex align-center justify-center mx-3">

            </span>
            <p>
               <slot/>
            </p>
        </span>
        <span v-html="icon" class="mx-3"></span>
    </Button>
</template>

<script>
export default {
    name: "InputBoolean",
    props: {
        name: {
            type: String,
            default: "",
        },
        form: {
            type: Object,
            default: ()=>{},
        },
        width: {
            type: String,
            default: "425px",
        },
    },
    computed: {
        PhoneActive() {
            return this.form[this.name] ? "primary white--text" : "white";
        },
        icon() {
            return this.form[this.name]
                ? '<i class="fa-regular fa-square-check fa-lg"></i>'
                : '<i class="far fa-square fa-lg"></i>';
        },
    }
}
</script>