<template>
  <ValidationProvider ref="ValidationProvider_ref" v-slot="{ errors }" v-bind="$attrs" v-on="$listeners">
    <slot :attrs="{
      'error-messages':
        (serverErrors && serverErrors[$attrs.errorName]) || errors,
    }" :hasError="errors[0] || (serverErrors && serverErrors[$attrs.errorName])" />

  </ValidationProvider>
</template>

<script>
export default {
  name: "FormGroup",
  inheritAttrs: false,
  props: {
    top: {
      type: String,
      default: "",
    },
    margin: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  mounted() { },
  methods: {
    validateField(callbackFunction) {
      this.$refs.ValidationProvider_ref.validate().then(({ valid }) => {
        if (valid) {
          callbackFunction();
        }
      });
    },
  },
};
</script>
