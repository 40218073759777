export const tagRoutes = {
  path: "tags",
  to: "/dynamic-services/:slug/booking/:id/tags",
  component: () => import("@/views/dynamic-service/booking/tags/actions"),
  
  icon: "all_tags.svg",
  allowed: true,
  order : 10,
  activeRoutes: ["term_records"],
  meta: {
      permissions : "services-booking-models_view",
      title : "tags",
  }

};