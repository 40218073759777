export const mediaRoutes = {
  path: "media",
  to: "/dynamic-services/:slug/booking/:id/media",
  component: () => import("@/views/dynamic-service/booking/media"),
  icon: "setting-img.svg",
  allowed: true,
  order: 2,
  activeRoutes: ["term_records"],
  meta: {
    permissions: "services-booking-services-medias_create",
    title: "media",
  },
};
