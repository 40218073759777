import Vue from "vue";
import "@/shared";
import "@/mixins";
// import "@/permission";
import "@/assets/scss/main.scss";
import "@/assets/fonts/main.scss";
import "@/plugins/vee-validate";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
import vuetify from "@/plugins/vuetify";
import i18n from "@/plugins/i18n";
import axios from "@/plugins/axios"

Vue.prototype.$eventBus = new Vue();
Vue.prototype.axios = axios
Vue.config.productionTip = false;

new Vue({
  store,
  router,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
