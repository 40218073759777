export const excludeRoutes = {
  path: "/services/:slug/excludes",
  to: "/services/:slug/excludes",
  component: () => import("@/views/system/services/exclude"),

  icon: "exclude.svg",
  allowed: true,
  order: 12,
  activeRoutes: ["exclude_records"],
  meta: {
    permissions: "services-excludes_view",
    title: "exclude_records",
  },
  children: [
    {
      path: "",
      component: () => import("@/views/system/services/exclude/record/index"),

      icon: "arrow.svg", // < arrow icon
      to: "/services/:slug/exclude",
      allowed: true,
      meta: {
        title: "record_exclude",
        permissions: "services-excludes_view",
      },
    },
  ],
};
