<template>
  <v-text-field v-if="onlyNumber" v-bind="$attrs" v-on="$listeners" @input="clearServerErrors">
    <template #append>
      <slot />
    </template>
  </v-text-field>
  <v-text-field v-else-if="normalField" v-bind="$attrs" v-on="$listeners" @input="clearServerErrors"></v-text-field>
  <v-text-field v-else-if="!onlyNumber" v-bind="$attrs" v-on="$listeners" @input="clearServerErrors">
    <template #append>
      <slot name="append-icon" />
    </template>
  </v-text-field>
</template>

<script>
export default {
  name: "TextInput",
  props: {
    onlyNumber: {
      type: Boolean,
      default: false
    },
    normalField: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    clearServerErrors() {
      if (this.serverErrors) {
        this.$store.dispatch("ClearServerErrors");
      }
    }
  }
};
</script>
