<template>
  <ShimmerLoader type="table" :loading="loading">
    <transition name="fade" mode="out-in">
      <v-data-table @scroll="onScroll" v-if="toggleTable" :mobile-breakpoint="0" :sort-desc.sync="sortDesc"
        :items="tableItems" :loading="loading" hide-default-footer class="card-shadow" :items-per-page="per_page"
        :no-data-text="$t('no_data')" :loading-text="$t('loading')" v-bind="$attrs" v-on="$listeners" item-key="id"
        ref="table_ref" v-model="selected">
        <template #header>
          <thead>
            <tr>
              <th class="text-start" v-for="(header, index) in headers" :key="index">
                <slot v-if="header.value" :name="'header-' + header.value">
                  {{ $t(`table.${header.value}`) }}
                </slot>
              </th>
              <th class="d-lg-none">
                {{ $t('table.controls') }}
              </th>
            </tr>
          </thead>
        </template>
        <!-- :custom-sort="customSort" -->
        <template #item="{ item, index, expand, isExpanded }">
          <!-- :style="['position: relative' , hoveredIndex === index ? 'background-color: #f03' : '']" -->
          <tr class="d-lg-table-row d-none" @mouseenter="hoverHandler(index)" @mouseleave="hoverHandler(-1)"
            style="position: relative" :class="{ 'active-row': hoveredIndex === index }">
            <td v-if="showSelect">
              <v-checkbox on-icon="fa-regular fa-square-check fa-lg" off-icon="far fa-square fa-lg" class="table-checkbox"
                v-model="item.checked" :ripple="false"></v-checkbox>
            </td>
            <slot :item="item" :index="index" :expand="expand" :is-expanded="isExpanded"></slot>
            <slot name="hoverd_row" v-if="hoveredIndex === index && !item.checked" :item="item"></slot>
          </tr>
          <tr class="d-lg-none d-table-row">
            <td></td>
            <slot :item="item" :index="index" :expand="expand" :is-expanded="isExpanded"></slot>
            <slot name="hoverd_row" :item="item"></slot>
          </tr>
        </template>
        <!-- <slot /> -->
        <template slot="no-data">
          {{ $t(`no_data`) }}
        </template>
        <template #expanded-item="{ item, headers }">
          <slot name="expend_row" :item="item" :headers="headers" color="primary" />

          <!-- <slot
              name="hoverd_expanded_row"
              v-if="hoveredIndex === index && !item.checked"
              :item="item"
            >
            
            </slot> -->
          <!-- </tr> -->
        </template>
      </v-data-table>
    </transition>

    <v-row class="my-3 mb-10" v-if="showPagination">
      <v-col cols="12" class="align-self-center order-lg-2">
        <Pagination />
      </v-col>
    </v-row>
  </ShimmerLoader>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "CustomTable",
  inheritAttrs: false,

  props: {
    reqName: {
      type: String,
      default: "",
    },
    tableHeaders: {
      type: Array,
      default: () => [],
    },
    itemsData: {
      type: Array,
      default: () => [],
    },
    fromStore: {
      type: Boolean,
      default: true,
    },
    showPagination: {
      type: Boolean,
      default: true,
    },
    showSelect: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      headers: [],
      loading: true,
      selected: [],
      sortDesc: true,
      toggleTable: true,
      disableSort: false,
      per_page: 20,
      hoveredIndex: -1,
      expanded: [],
      singleExpand: false,
    };
  },
  computed: {
    ...mapGetters({
      items: "getsTableItems",
      pagination: "getPagination",
    }),
    tableItems() {
      if (this.fromStore) {
        return this.items;
      } else {
        return this.itemsData;
      }
    },
  },
  mounted() {
    this.$eventBus.$off();
    this.$eventBus.$on("on-refresh", () => {
      this.toggleTable = false;
      setTimeout(() => {
        this.toggleTable = true;
      }, 0);
    });
    this.$eventBus.$on("on-refetch-data", () => {
      this.loading = true
      this.getTableData();

    });
  },
  created() {
    this.createTableHeaders();
    if (this.reqName) {
      this.getTableData();
    }
  },
  methods: {
    ...mapActions({ getTableItems: "getTableItems" }),
    getTableData() {
      this.getTableItems({ reqName: this.reqName }).then(() => {
        this.loading = false;
      });
    },
    generateTableHeaders(headersName = []) {
      return headersName.map((header) => {
        return {
          text: header ? this.$t(`table.${header.name}`) : "",
          value: header.value,
          align: header.align ?? "start",
          sortable: header.sortable ? header.sortable : false,
        };
      });
    },
    createTableHeaders() {
      this.headers = this.generateTableHeaders(this.tableHeaders);
    },
    handleGetPerPage() {
      this.$store.dispatch("setQuery", {
        ...this.getQuery,
        per_page: this.per_page,
        page: 1,
      });
    },
    onScroll(e) {
      const { scrollTop, scrollHeight, clientHeight } = e.target;

    },
    hoverHandler(index) {
      this.hoveredIndex = index;
    },
  },
  watch: {
    getQuery: {
      handler(query) {
        if (query && Object.keys(query).length > 0) {
          this.getTableData();
        }
      },
      immediate: false,
    },
    reqName: {
      handler(value) {
        if (!value) this.loading = false;
      },
      immediate: true,
    },
  },
};
</script>
