export const rolesAndUsersRoutes = {
  path: "/",
  to: "/",
  icon: "user.svg",
  activeRoutes: [],
  meta: {
    title: "rolesAndUsers",
    permissions: ["users_view","roles_view"],
  },
  order: 2,
  allow_children: true,
  showInMenu: true,
  
  component: () => import("@/views/roles-users"),
  // user routes
  children: [
    {
      path: "/users",
      to: "/users",
      icon: "arrow.svg",
      activeRoutes: ["record_users", "add_user", "user_user"],
      allowed: true,
      hideInMenu: false,
      allow_children: false,
      
      meta: {
        title: "users",
        permissions: "users_view",
      },
      component: () => import("@/views/roles-users/users"),
      children: [
        {
          path: "",
          component: () => import("@/views/roles-users/users/record/index"),
          
          icon: "arrow.svg", // < arrow icon
          to: "/users",
          allowed: true,
          meta: {
            title: "record_users",
            permissions: "users_view",
          },
        },
        {
          path: "add",
          component: () => import("@/views/roles-users/users/actions"),
          
          icon: "arrow.svg",
          to: "/users/add",
          allowed: true,
          meta: {
            title: "add_user",
            permissions: "users_create",
          },
        },
        {
          path: ":id/edit",
          component: () => import("@/views/roles-users/users/actions"),
          
          icon: "mdi-square-show-outline",
          hidden: true,
          meta: {
            title: "users_show",
            permissions: "users_edit",
          },
        },
      ],
    },
    {
      path: "/roles",
      to: "/roles",
      icon: "arrow.svg",
      activeRoutes: ["record_roles", "add_roles", "roles"],
      meta: {
        title: "roles",
        permissions: "roles_view",
      },
      allowed: true,
      hideInMenu: false,
      allow_children: false,
      
      component: () => import("@/views/roles-users/roles"),
      children: [
        {
          path: "/",
          component: () => import("@/views/roles-users/roles/record"),
          
          icon: "mdi-file-document-multiple-outline",
          to: "/roles/record",
          allowed: true,
          meta: {
            title: "record_roles",
            permissions: "roles_view",
          },
        },
        {
          path: "add",
          component: () => import("@/views/roles-users/roles/actions"),
          
          hidden: true,
          icon: "mdi-plus-circle-outline",
          meta: {
            title: "roles_create",
            permissions: "roles_create",
          },
        },
        {
          path: ":id/edit",
          component: () => import("@/views/roles-users/roles/actions"),
          
          icon: "mdi-square-edit-outline",
          hidden: true,
          meta: {
            title: "roles_edit",
            permissions: "roles_edit",
          },
        },
      ],
    },
  ],
};
