import { asyncRoutes } from "@/router/asyncRoutes";
import store from "@/store";
import {dashboardRoutes} from "@/router/modules/dashboard"
export default {
  state: () => ({
    routes: [],
    allRoutes: [],
  }),
  mutations: {
    SET_ALL_ROUTES(state, payload) {
      state.allRoutes = payload;
    },
    SET_ROUTES(state, payload) {
      state.routes = payload;
    },
    SET_CREATE_PERMISSION(state, payload) {
      state.hasCreatePermission = payload;
    },
    SET_EDIT_PERMISSION(state, payload) {
      state.hasEditPermission = payload;
    },
    SET_DELETE_PERMISSION(state, payload) {
      state.hasDeletePermission = payload;
    },
  },
  actions: {
    setRoutes({ commit }) {
      commit("SET_ALL_ROUTES", asyncRoutes);
    },

    setAllowedRoutes({ commit }, { permissions, id }) {
      if (id == 0) {
        const userPermissions = permissions
          .map((permission) =>
            permission.sub_permissions.map((subPermission) =>
              subPermission.name.replaceAll(/[/ ]+/g, "-").toLowerCase()
            )
          )
          .flat();
        const services = async () => {
          return await store.dispatch("setServices", userPermissions);
        };

        services().then((result) => {
          const contentRoutes = {
            path: "/dynamic-services",
            to: "/dynamic-services",
            icon: "model-white.svg",
            activeRoutes: ["record_dynamic"],
            meta: {
              title: "dynamic_service",
              permissions: "dynamic_services",
            },
            allow_children: true,
            showInMenu: true,
            order: 4,
            component: () => import("@/views/dynamic-service"),
            children: result,
          };
          asyncRoutes.push(contentRoutes);
          commit("SET_ROUTES", asyncRoutes);
        });
      } else {
        // array of permission strings
        const userPermissions = permissions
          .map((permission) =>
            permission.sub_permissions.map((subPermission) =>
              subPermission.name.replaceAll(/[/ ]+/g, "-").toLowerCase()
            )
          )
          .flat();
        console.log("user permissions", userPermissions);
        //  check if route permissions are allowed
        const hasPermission = (routePermissions) => {
          if (Array.isArray(routePermissions)) {
            return routePermissions.some((permission) =>
              userPermissions.includes(permission)
            );
          }
          return userPermissions.includes(routePermissions);
        };

        //i make  Filter parent routes
        const allowedParentRoutes = asyncRoutes.filter((route) =>
          hasPermission(route.meta.permissions)
        );

        // i make  Filter children routes
        const allowedRoutesWithChildren = allowedParentRoutes.map(
          (parentRoute) => {
            if (parentRoute.children) {
              parentRoute.children = parentRoute.children.filter((childRoute) =>
                hasPermission(childRoute.meta.permissions)
              );
              //  filter children of children
              parentRoute.children.forEach((childRoute) => {
                if (childRoute.children) {
                  childRoute.children = childRoute.children.filter(
                    (grandChildRoute) =>
                      hasPermission(grandChildRoute.meta.permissions)
                  );
                }
              });
            }
            return parentRoute;
          }
        );
        if (userPermissions.includes("services_view")) {
          const services = async () => {
            return await store.dispatch("setServices", userPermissions);
          };
          services().then((result) => {
            const contentRoutes = {
              path: "/dynamic-services",
              to: "/dynamic-services",
              icon: "model-white.svg",
              activeRoutes: ["record_dynamic"],
              meta: {
                title: "dynamic_service",
                permissions: "dynamic_services",
              },
              allow_children: true,
              showInMenu: true,
              order: 4,
              component: () => import("@/views/dynamic-service"),
              children: result,
            };
            allowedRoutesWithChildren.push(contentRoutes);
            allowedRoutesWithChildren.push(dashboardRoutes)
          
            commit("SET_ROUTES", allowedRoutesWithChildren);
          });
        } else {
          allowedRoutesWithChildren.push(dashboardRoutes)
          commit("SET_ROUTES", allowedRoutesWithChildren);
        }
      }
    },
  },
  getters: {
    getRoutes(state) {
      return state.routes.sort((routeA, routeB) => {
        const orderA = routeA.order || 0;
        const orderB = routeB.order || 0;
        return orderA - orderB;
      });
    },
    getCompanyRoutes(state) {
      const companyRoute = asyncRoutes.find(
        (route) => route.meta.permissions === "companies"
      );
      return (
        companyRoute?.children?.find((route) => route?.children?.length)
          ?.children || []
      );
    },
    getServiceRoutes(state) {
      const systemRoute = asyncRoutes.find((route) =>
        route.meta.permissions.includes("services_view")
      );
      const servicesRoute = systemRoute?.children?.find(
        (route) => route.meta.permissions === "services_view"
      );
      return (
        servicesRoute?.children?.find((route) => route?.children?.length)
          ?.children || []
      );
    },
    getBookingRoutes(state) {
      const dynamicServicesRoute = asyncRoutes.find(
        (route) => route.meta.permissions === "dynamic_services"
      );
      const modelsRoute = dynamicServicesRoute?.children?.find(
        (route) => route.meta.permissions === "models"
      );
      const bookingRoute = modelsRoute?.children?.find(
        (route) => route.meta.permissions === "booking"
      );
      return (
        bookingRoute?.children?.find((route) => route?.children?.length)
          ?.children || []
      );
    },
    getRentalRoutes(state) {
      const dynamicServicesRoute = asyncRoutes.find(
        (route) => route.meta.permissions === "dynamic_services"
      );
      const modelsRoute = dynamicServicesRoute?.children?.find(
        (route) => route.meta.permissions === "models"
      );
      const rentalRoute = modelsRoute?.children?.find(
        (route) => route.meta.permissions === "rental"
      );
      return (
        rentalRoute?.children?.find((route) => route?.children?.length)
          ?.children || []
      );
    },
    getOfferRoutes(state) {
      const contentRoute = asyncRoutes.find((route) =>
        route.meta.permissions.includes("offers_view")
      );
      const offersRoute = contentRoute?.children?.find(
        (route) => route.meta.permissions === "offers_view"
      );
      return (
        offersRoute?.children?.find((route) => route?.children?.length)
          ?.children || []
      );
    },
  },
};
