<template>
  <v-card class="tabs" style="height: 100%">
    <v-tabs vertical>
      <v-tab class="tabs__tab" style="overflow: hidden" v-for="(item, index) in filteredItems" :key="index"
        :to="item.path">
        <div class="tabs__tab__icon mx-3">
          <div v-html="item.icon"></div>
        </div>
        <div class="font-weight-bold">
          <p>
            {{ item.title }}
          </p>
        </div>
      </v-tab>
    </v-tabs>
  </v-card>
</template>

<script>
export default {
  name: "Tabs",
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    filteredItems() {
      return this.items.filter((item) => item.showInTabs);
    },
  },
};
</script>