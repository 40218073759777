const base = function () {
  if (process.env.NODE_ENV === "production") {
    return 'https://api.otasapp.com/api/admin-panel'
  } else if (process.env.NODE_ENV === "testing") {
    return 'https://test.api.otasapp.com/api/admin-panel'
  }
  else {
    return 'https://dev.api.otasapp.com/api/admin-panel'
  }
};
export const baseURL = base();

