export const targetRoutes = {
  path: "/countries/:id/target",
  to: "/countries/:id/target",
  icon: "mdi-account-multiple-outline",
  activeRoutes: ["record_target"] ,
  allow_children: false,
//   order : 16,
  showInMenu: true,
  
  meta: {
      title: "view-target",
      permissions: "countries-target",
      
  },
  component: () => import("@/views/countries/target"),
  children: [
      {
          path: "/",
          component: () => import("@/views/countries/target/record"),
          
          icon: "mdi-file-document-multiple-outline",
          allowed: true,
          meta: {
              title: "record_target",
              permissions: "countries-target_view"
          }
      },
  ],
};
