import { asyncSettingRoutes } from "@/router/settingsRoutes"
export const settingsRoutes = {
  path: "/settings",
  to: "/settings",
  icon: "system.svg",
  activeRoutes: ["record_settings"],
  meta: {
    title: "settings",
    permissions: ["countries_view","settings_view"],
  },
  name: "settings",
  allow_children: true,
  showInMenu: true,
  order: 7,
  component: () => import("@/views/settings"),
  children: [
    {
      path: "/countries",
      component: () => import("@/views/countries/record"),
      icon: "arrow.svg", // < arrow icon 
      to: "/countries",
      allowed: true,
      meta: {
        title: "countries",
        permissions: "countries_view",
      },
    },
    {
      path: "main_informations",
      component: () => import("@/layout/settingLayout"),
      icon: "arrow.svg", // < arrow icon 
      to: "/settings/main_informations",
      allowed: true,
      meta: {
        title: "all_settings",
        permissions: "settings_view",
      },
      children: [
        ...asyncSettingRoutes
      ]
    }


  ]
};
