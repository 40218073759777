<template>
  <div>
    <v-switch v-model="value" readonly @click=" disabled ? '':  dialog = !dialog;" inset hide-details v-bind="$attrs" v-on="$listeners"
      color="secondary"></v-switch>
    <GenericDialog :persistent="false" :dialog.sync="dialog"
      :title="popup.title ? !value ? $t(popup.title) : $t(`toggle_${popup.title}`) : ''"
      :description="!value ? $t(popup.description) : $t(`toggle_${popup.description}`)" :icon="popup.icon">
      <div class="d-flex align-center gap-10 px-0">
        <Button :loading="loading" large :color="popup.colorBtn ?? 'error'"
          class="px-10 shadow text-capitalize white--text" block @click="toggleRequest">
          {{ !value ? $t(popup.confirmBtn) : $t(`toggle_${popup.confirmBtn}`) }}
        </Button>
        <Button v-if="showCancel" large color="grey" class="px-10 shadow text-capitalize white--text" block
          @click="dialog = false;">
          {{ $t("buttons.cancel") }}
        </Button>
      </div>
    </GenericDialog>
  </div>
</template>
<script>
import { updateToggle } from "@/utils/toggle";
export default {
  props: {
    disabled:{
      default:false,
    },
    popup: {
      type: Object,
      default: () => { },
    },
    modelName: {
      type: String,
      default: null,
    },
    value: {
      default: null
    },
    modelId: {
      type: [String, Number],
      default: null,
    },
    type: {
      type: String,
      default: "visible",
    },
    icon: {
      type: String,
      default: "block-user.svg"
    },
    showCancel: {
      type: Boolean,
      default: true
    },
    refetch:{
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      dialog: false,
      loading: false
    };
  },
  methods: {
    toggleRequest() {
      let reqData = {
        name: this.modelName,
        id: this.modelId,
        visible: !this.value,
        type: this.type,
        locale: this.$i18n.locale,
      };
      if (this.refetch) {
        this.loading = true
        updateToggle(reqData)
          .then(() => {
            this.dialog = false;
            this.$eventBus.$emit("on-refetch-data");
          }).finally(() => {
            this.loading = false
          })
      } else {
        this.dialog = false;
        this.$emit('changed', !this.value)
      }
    }
  },
};
</script>
