export const mainInfoRoutes = {
  path: "main_informations",
  to: "/dynamic-services/:slug/booking/:id/main_informations",
  component: () => import("@/views/dynamic-service/booking/actions"),
  icon: "main_info.svg",
  allowed: true,
  order : 1,
  activeRoutes: ["mainInfo_records"],
  meta: {
      permissions : "services-booking-models_view",
      title : "main_info_record_models",
  }
};