export const tagRoutes = {
  path: "tags",
  to: "/dynamic-services/:slug/rental/:id/tags",
  component: () => import("@/views/dynamic-service/rental/tags/actions"),
  icon: "all_tags.svg",
  allowed: true,
  order: 7,
  activeRoutes: ["tag_records"],
  meta: {
    permissions: "services-rental-models_edit",
    title: "tags",
  },
};
