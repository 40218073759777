<template>
  <v-autocomplete
    v-bind="$attrs"
    v-on="$listeners"
    :no-data-text="$t('no_data')"
  >

    <template #prepend-inner> 
      <slot name="prepend-icon" /> 
    </template>
    <template #item="{item}" v-if="hasItemSlot"> 
      <slot name="item" :item="item" /> 
    </template>
   <template #selection="{ item }"  v-if="hasSelectionSlot"
>
      <slot name="selection" :item="item" />
    </template> 

  </v-autocomplete>
</template>

<script>
export default {
  name: "AutoComplete",
  props: {
    hasItemSlot: {
      type: Boolean,
      default: false
    },
    hasSelectionSlot: {
      type: Boolean,
      default: false
    }
  }
};
</script>
