<template>
    <v-row no-gutters
        :class="`active-card  position-relative px-5 ${bgColor}  ${borderType} ${paddingTop}  ${paddingBottom}  ${marginBottom}`">
        <v-col cols="12" :sm="left">
            <h4 :class="`${headingClass}`">
                {{ $t(title) }}
            </h4>
            <p class="mt-3 active-card__description">
                {{ $t(hint) }}
            </p>
        </v-col>
        <v-col cols="12" :sm="right">
            <slot />
        </v-col>
    </v-row>
</template>
<script>
export default {
    name: "CardInfo",
    props: {
        title: {
            type: String,
            default: ""
        },
        hint: {
            type: String,
            default: ""
        },
        left: {
            type: String,
            default: "6"
        },
        right: {
            type: String,
            default: "6"
        },
        marginBottom: {
            type: String,
            default: "mb-5"
        },
        paddingTop: {
            type: String,
            default: "pt-7"
        },
        paddingBottom: {
            type: String,
            default: "pb-16"
        },
        borderType: {
            type: String,
            default: "border-section"
        },
        bgColor: {
            type: String,
            default: 'white'
        },
        headingClass: {
            type: String,
            default: ''
          
        }
    }
}
</script>