<template>
    <div class="upload-file">
        <v-label v-if="name" :for="name">
            <strong class="font-weight-dark"> {{ $t(name) }}</strong>
        </v-label>
        <v-file-input v-bind="$attrs" v-on="$listeners" :hint="$t(hint)" persistent-hint @change="upload"
            :multiple="multiple" v-model="files" color="primary accent-4" outlined>
            <template v-slot:selection="{ index, text }">
                <v-chip v-if="index < 2" color="primary accent-4" dark label small>
                    {{ text }}
                </v-chip>
                <span v-else-if="index === 2" class="size-14 grey--text text--darken-3 mx-2">
                    + {{ files.length - 2 }}
                </span>
            </template>
            <template slot="append">
                <img :src="require(`@/assets/imgs/icons/file.svg`)" alt="icon">
            </template>
        </v-file-input>
        <div class="d-flex">
            <div v-if="!src && showExample && form[keyFile]" class="upload-file__example">
                <img class="w-100 h-100" :src="form[keyFile].path">
                <p class="upload-file__example__text">
                    {{ $t('example') }}
                </p>
            </div>
            <div class="d-flex" v-if="multiple">
                <div v-for="(file, index) in files" :key="index" class="upload-file__example px-4">
                    <img class="w-100 h-100" :src="file.path" alt="image">
                </div>
            </div>
            <div v-else-if="src && !multiple" class="upload-file__example">
                <img class="w-100 h-100" :src="src" alt="image">
                <p class="upload-file__example__text">
                    {{ $t('example') }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "File",
    data: () => ({
        files: [],
        src: null
    }),
    mounted() {
        this.files = []
    },
    methods: {
        upload() {
            if (this.multiple) {
                this.multi()
            } else {
                this.single()
            }
            this.$emit('uploaded', this.files)
    
        },
        single() {
            this.src = URL.createObjectURL(this.files);
        },
        multi() {
            for (let i = 0; i < this.files.length; i++) {
                this.files[i].path = URL.createObjectURL(this.files[i]);
            }
        }
    },
    props: {
        multiple: {
            type: Boolean,
            default: false
        },
        showExample: {
            type: Boolean,
            default: false
        },
        name: {
            type: String,
            default: ""
        },
        hint: {
            type: String,
            default: ""
        },
        keyFile: {
            type: String,
            default: ""
        },
        form: {
            type: Object,
            default: () => { }
        }
    }
}
</script>