import countryLayout from "@/layout/countryLayout";
import {asyncRoutes} from "@/router/countryRoutes";
export const countriesRoutes = {
  path: "/countries",
  to: "/countries",
  icon: "mdi-earth",
  activeRoutes: ["record_countries"],
  showInMenu: false,
  meta: {
    title: "countries",
    permissions:[ "countries_view","sections_view"]
  },
  // order : 11,
  allow_children: false,
  component: () => import("@/views/countries"),
  children: [
    {
      path: "/",
      component: () => import("@/views/countries/record"),
      icon: "mdi-file-document-multiple-outline",
      to: "/countries/record",
      allowed: true,
      meta: {
        title: "record_countries",
        permissions: "countries_view"

      }
    },
    {
      path: ":id",
      component: countryLayout ,
      icon: "mdi-file-document-multiple-outline",
      allowed: true,
      meta: {
        title: "record_countries",
        permissions: "countries_view"

      },
      redirect: "/countries/:id/informations",
      children:[
          ...asyncRoutes
      ]
  },
  ]
};
