const asyncBookingRoutes = [];
const context = require.context('./bookingModules', false, /\.js$/);
context.keys().forEach((key) => {
  let fileName = key.replace(/(\.\/|\.js)/g, "");
  asyncBookingRoutes.push(context(key)[fileName + "Routes"]);
});

asyncBookingRoutes.sort((routeA, routeB) => {
  const orderA = routeA.order || 0;
  const orderB = routeB.order || 0;
  return orderA - orderB;
});

export { asyncBookingRoutes };