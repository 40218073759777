const company = {
   state:()=>{
    return {
        isEntity:false , 
        isPersonal:false
    }
   },
    mutations: {
    SET_IS_COMPANY(state, payload){
        console.log(payload);
        state.isEntity = payload.isEntity ?? false;
        state.isPersonal = payload.isPersonal ?? false
    }
    },
   getters:{
    getCompany:(state) => ({isEntity : state.isEntity , isPersonal : state.isPersonal})
   }
  };
  
  export default company;
  