import serviceLayout from "@/layout/serviceLayout";
import { asyncRoutes } from "@/router/servicesRoutes";

export const systemRoutes = {
  path: "/",
  to: "/",
  icon: "add-service-white.svg",
  activeRoutes: ["record_system"],
  meta: {
    title: "system",
    permissions: ["services_view", "sections_view"],
  },
  order: 6,
  name: "system",
  allow_children: true,
  showInMenu: true,

  component: () => import("@/views/system"),
  children: [
    {
      path: "/services",
      component: () => import("@/views/system/services"),

      icon: "arrow.svg", // < arrow icon
      to: "/services",
      hidden: true,
      activeRoutes: ["record_services", "add_service"],
      allowed: true,
      allow_children: true,
      meta: {
        title: "services",
        permissions: "services_view",
      },
      children: [
        {
          path: "add",
          component: () => import("@/views/system/services/add"),

          icon: "arrow.svg", // < arrow icon
          to: "/services/add",
          allow_children: true,
          activeRoutes: ["add_service"],
          allowed: true,
          meta: {
            title: "add_service",
            permissions: "services_create",
          },
        },
        {
          path: "",
          component: () => import("@/views/system/services/record"),

          icon: "arrow.svg", // < arrow icon
          to: "/services",
          allow_children: true,
          activeRoutes: ["record_services"],
          allowed: true,
          meta: {
            title: "record_services",
            permissions: "services_view",
          },
        },
        {
          path: ":slug",
          component: serviceLayout,

          icon: "mdi-file-document-multiple-outline",
          allowed: false,
          redirect: "/services/:slug/dashboard",
          children: [...asyncRoutes],
          meta: {
            title: "record_services",
            permissions: "services_view",
          },
        },
      ],
    },
    {
      path: "/homepage",
      component: () => import("@/views/system/homepage/record"),

      icon: "arrow.svg", // < arrow icon
      to: "/homepage",
      activeRoutes: ["record_homepage", "add_homepage"],
      allowed: true,
      meta: {
        title: "homepage",
        permissions: "sections_view",
      },
    },
  ],
};
