export const hotelNightsRoutes = {
  path: "hotel-nights",
  component: () => import("@/views/dynamic-service/booking/hotels"),
  icon: "hotel_nights.svg", // < arrow icon 
  to: "/dynamic-services/:slug/booking/:id/hotel-nights",
  hidden: false,
  activeRoutes: ["record_hotels", "add_hotel"],
  allowed: true,
  allow_children: true,
  order:11,
  meta: {
    title: "hotel-nights",
    permissions: "services-booking-models-hotel-nights_view",
  },
  children: [
    {
      path: "",
      component: () => import("@/views/dynamic-service/booking/hotels/record"),
      
      icon: "arrow.svg", // < arrow icon 
      to: "/dynamic-services/:slug/booking/:id/hotel-nights",
      allow_children: true,
      activeRoutes: ["record_hotels"],
      allowed: true,
      meta: {
        title: "record_hotels",
        permissions: "services-booking-models-hotel-nights_view",
      },
    },


    {
      path: "add",
      component: () => import("@/views/dynamic-service/booking/hotels/actions"),
      
      icon: "arrow.svg",
      to: "/dynamic-services/:slug/booking/:id/hotel-nights/add",
      allowed: false,
      meta: {
        title: "add_user",
        permissions: "services-booking-models-hotel-nights_create",
      },
    },
    {
      path: ":hotel_id/edit",
      to: "/dynamic-services/:slug/booking/:id/hotel-nights/:hotel_id/edit",

      component: () => import("@/views/dynamic-service/booking/hotels/actions"),
      
      icon: "mdi-square-show-outline",
      hidden: true,
      meta: {
        title: "edit_hotels",
        permissions: "services-booking-models-hotel-nights_edit",
      },
    },
   
   

  ]
};