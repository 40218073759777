<template>
  <div>
    <Preloader />
    <AppHeader />
    <AppSideBar />
    <router-view />
  </div>
</template>
<script>
export default {
  components: {
    AppHeader: () => import("@/components/Layout/Header"),
    AppSideBar: () => import("@/components/Layout/SideBar"),
    Preloader: () => import("@/components/Preloader")
  }
};
</script>
