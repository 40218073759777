<template>
  <div class="text-center pagination-wrapper py-4">
    <v-pagination
      :value="pagination.current_page"
      :length="pagination.last_page"
      :total-visible="10"
      @input="handlePagination"
    ></v-pagination>
    <div class="pagination_info">
        {{$t("pagination.the_page")}} {{pagination.current_page}} {{$t("pagination.of")}} {{pagination.last_page}} {{$t("pagination.page")}}
    </div>
  </div>
</template>

<script>
import { isEqual } from "lodash";
import { mapGetters } from "vuex";
export default {
  name: "Pagination",
  data() {
    return {
      queries: {}
    };
  },
  computed: {
    ...mapGetters({
      items: "getsTableItems",
      pagination: "getPagination"
    
    })
  },
  watch: {
    items: {
      handler(val) {
        const { current_page, last_page } = this.pagination;
        if (val.length === 0 && current_page >= last_page && last_page > 1) {
          this.$store.dispatch("setQuery", {
            ...this.getQuery,
            page: current_page - 1
          });
        }
        if (
          val.length > this.pagination.per_page &&
          this.getQuery.page >= last_page
        ) {
          this.$store.dispatch("setQuery", {
            ...this.getQuery,
            page: current_page + 1
          });
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    handlePagination(page) {
      this.queries = { ...this.queries, ...this.getQuery, page };
      if (!isEqual(this.queries, this.getQuery)) {
        this.$store.dispatch("setQuery", this.queries);
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    }
  }
};
</script>
