import { getToken, setToken, removeToken } from "@/utils/auth";
import Cookies from "js-cookie";
import axios from "@/plugins/axios";
const user = {
  state: {
    token: getToken(),
    user: "",
    permissions: []
  },
  mutations: {
    SET_USER(state, user) {
      state.user = user;
    },
    SET_TOKEN(state, token) {
      state.token = token;
      setToken(token);
    },
    SET_PERMISSIONS(state, permissions) {
      state.permissions = permissions;
    }
  },
  actions: {
    Login({ commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        axios.post(`signin`, payload)
          .then((response) => {
            const { data } = response;
            commit("SET_TOKEN", data.access_token);
            dispatch("GetUserInfo").then(() => {
              resolve();
            });
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    GetUserInfo({ commit, dispatch }) {
      return new Promise((resolve, reject) => {
        axios.get(`profile/username`)
          .then((res) => {
            const { user } = res.data;
            
            commit("SET_USER", user);
          // dispatch("setRoutes");
             dispatch("setPermissions", user);
             dispatch("setLanguages");
            resolve(user);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    setPermissions({ commit, dispatch }, { role }) {
      let permissions = role.permissions;
      // if role is super admin add all permissions 
      if (role.id === 0) {
        permissions.push({
          name: "roles",
          sub_permissions: [{ name: "view" }, { name: "create" }, { name: "edit" }, { name: "delete" }]
        })
      }
      commit("SET_PERMISSIONS", permissions);
      dispatch("setAllowedRoutes", role);
    },

    Logout({ commit }) {
      return new Promise((resolve, reject) => {
        commit("SET_TOKEN", "");
        removeToken();
        Cookies.remove("permissions");
        return resolve();
      });
    },
    setUserInfo({ commit }, user) {
      commit("SET_USER", user);
    },
  },
  getters: {
    getPermissions(state) {
      return state.permissions;
    },
    getUser(state) {
      return state.user;
    },
  },
};

export default user;