import axios from "@/plugins/axios";
export default {
   state : () => ({
    rental: {},

  })
,
  mutations : {
    SET_RENTAL(state, payload) {
      state.rental = payload
    }
  },
  actions : {
    async setRental({ commit }, url ) {
      const res = await axios.get(url);
      const rental_model = res.data.rental_model;

      commit('SET_RENTAL', rental_model)
      return Promise.resolve();
    }
  },

  getters : {
    getRental(state) {
      return state.rental
    },

  }

}

