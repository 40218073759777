export const policyRoutes = {
  path: "/services/:slug/policy",
  to: "/services/:slug/policy",
  component: () => import("@/views/system/services/policy/record"),

  icon: "policy.svg",
  allowed: true,
  order: 10,
  activeRoutes: ["policy_records"],
  meta: {
    permissions: "services-cancellation-policies_view",
    title: "policy",
  },
};
